function BraintreeProvider() {
  this.$get = function () {
    if (typeof (braintree) !== 'undefined') {
        return braintree;
    }
  };
}

angular
  .module('samcart')
  .provider('Braintree', BraintreeProvider)


angular.module('samcart').directive('countrySelect', function() {
    return {
        restrict:'C',
        link: function (scope, element, attrs) {
            var destroy = scope.$watch(attrs['ngModel'], function (v) {
                var initialize = function(){
                    window.setTimeout(function(){
                        if (typeof(scope.countries) !== "undefined") {
                            for(var i = 0; i < scope.countries.length; i++) {
                                if (scope.countries[i].id == v) {
                                    $(element).change();
                                }
                            }
                            destroy();
                        } else {
                            initialize();
                        }
                    }, 60);
                }
                initialize();
            });
        }
    };
});

angular.module('samcart.factories')
    .factory('Coupon', ['$http', function($http) {
        var Coupon = {};

        Coupon.check = function(data, fn) {
            $http.post('/api/coupons/check', data).success( function (data) {
                fn(data);
            });
        }

        Coupon.checkMultiple = function (data, fn) {
            $http.post('/api/coupons/check_multiple', data).success( function (data) {
                fn(data);
            });
        };

        return Coupon;
    }]);
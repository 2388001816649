/**
 * Format a number in the marketplace's currency.  Input should be a number (in cents).  Optionally specify
 * the number of decimals to include.
 *
 * Ex:  [[order.total | currency:0]] $45
 *      [[order.total | currency]] $45.00
 */
angular.module('samcart').filter('currency', ['Context', 'numberFilter', function(Context, numberFilter) {
    var currencySymbol = Context.store.currency;

    return function(value, precision) {
        var fractionSize = angular.isDefined(precision) ? precision : 2,
            amount;

        if (angular.isString(value)) {
            value = value.replace(/[^\d\.]/g,''); // remove anything other than digits and decimals
        }

        amount = parseFloat(value) / 100;

        if (amount < 0) {
            return '-' + currencySymbol + numberFilter(Math.abs(amount), fractionSize);
        } else {
            return currencySymbol + numberFilter(amount, fractionSize);
        }
    };
}]);
angular.module('samcart')
    .directive('modalscreen', ['$rootScope', 'Checkout', function($rootScope, Checkout) {
        return {
            restrict: 'E',
            template : "<div class='modal-screen'></div>",
            replace : true,
            link: function(scope, el, attrs) {

                el.hide();

                Checkout.listen( function() {
                    el.addClass('animated fadeIn');
                    $('body').css({"overflow":"hidden"});
                    el.show();
                }, "fade_in_modal_screen");

                Checkout.listen( function() {
                    $('body').css({"overflow":"hidden"});
                    el.show();
                }, "show_modal_screen");

                Checkout.listen( function() {
                    $('body').css({"overflow":"auto"});
                    el.hide();
                }, "hide_modal_screen");

            }
        };
    }]);

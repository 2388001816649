angular.module('samcart')
    .directive('boxLoading', function() {
        return {
            restrict: 'A',
            link: function(scope, el, attrs) {
                scope.$watch(attrs.boxLoading, function(value, old) {
                    var $boxes = el.is('.box') ? el : el.find('.box');
                    if (value) {
                        $boxes.addClass('box-loading');
                    } else {
                        $boxes.removeClass('box-loading');
                    }
                });
            }
        };
    });

angular.module('samcart.factories')
    .factory('Prospect', ['$timeout', '$http', function($timeout, $http) {
        var Prospect = {
            dirty : false,
            capture : { },

            // PROCESS THE INCOMING DATA & SEND TO THE SERVER IF VALID WITH CHANGES
            data : function(prospect, order, visitation_token, sandbox, callback) {
                // Set prospect
                var prospectValues = this.setProspect(order, visitation_token, sandbox);

                // If the data has changed, update
                if (this.isDirty(prospect, prospectValues) && this.emailIsValid(prospectValues.email)) {
                    
                    // Store the new prospect
                    this.capture = prospectValues;

                    // Post to Server
                    this.sendValues();

                    // Return the new object To Scope
                    callback(prospectValues);
                }
            },

            // CHECK IF THE DATA HAS CHANGED
            isDirty : function(obj1, obj2) {
                // Check Billing Address For Changes
                if (obj1 && obj2 && (obj1.billing_address || obj2.billing_address)) {
                    if (!(JSON.stringify(obj1.billing_address) === JSON.stringify(obj2.billing_address))) {
                        return true;
                    }
                }
                // Check Shipping Address For Changes
                if (obj1 && obj2 && (obj1.shipping_address || obj2.shipping_address)) {
                    if (JSON.stringify(obj1.shipping_address) !== JSON.stringify(obj2.shipping_address)) {
                        return true;
                    }
                }
                // Check Prospect For Changes
                if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
                    return false;
                }
                return true;
            },

            // POST TO THE SEVER
            sendValues : function() {
                var _this = this;
                $http.post('/api/prospect/capture/', this.capture).success(function(data) {
                    _this.postResponseFunction(data);
                });
            },

            // SERVER RESPONSE
            postResponseFunction : function(response) {
            },

            // HELPER (Set Prospect To Object, Detach From Scope)
            setProspect: function (order, visitation_token, sandbox) {
                var prospectValues = {
                    token: visitation_token,
                    first_name: order.customer.first_name,
                    last_name: order.customer.last_name,
                    email: order.customer.email,
                    phone_number: order.customer.phone_number,
                    billing_address: this.addressIsValid(order.billing_address) ? this.setAddress(order.billing_address) : null,
                    shipping_address: this.addressIsValid(order.shipping_address) ? this.setAddress(order.shipping_address) : null,
                    visitor_token: visitation_token,
                    product_id: order.product_id,
                    sandbox: sandbox
                };
                return prospectValues;
            },

            // HELPER (Set Address To Object, Detach From Scope)
            setAddress: function (address) {
                var addObj = {
                    address_line1: address.address,
                    address_line_2: null,
                    city: address.city,
                    region: address.region,
                    postal_code: address.postal,
                    country_id: address.country,
                };
                return addObj;
            },

            // EMAIL VAIDATION
            emailIsValid: function(email) {
                var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            // MAKE SURE ALL ADDRESS FIELDS ARE POPULATED
            addressIsValid: function (address) {
                if (address.address && address.city && address.region && address.postal && address.country) {
                    return true;
                }
                return false;
            },

        };

        return Prospect;
    }]);

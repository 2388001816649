$().ready(function() {
    $("#paymentForm").validate({
        rules: {
            fname: {
                required: true,
                pattern: /^(?!\s*$).+/
            },
            lname: {
                required: true,
                pattern: /^(?!\s*$).+/
            },
            email: {
                required: true,
                pattern: /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
            },
            email_confirmation: {
                required: true,
                pattern: /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
                equalTo: "#email"
            },
            phone: "required",
            billing_address: "required",
            billing_country: "required",
            billing_city: "required",
            billing_zip: "required",
            billing_postal_code: "required",
            billing_state: "required",
            billing_region: "required",
            shipping_address: "required",
            shipping_country: "required",
            shipping_city: "required",
            shipping_zip: "required",
            shipping_postal_code: "required",
            shipping_state: "required",
            shipping_region: "required",
            ccnum: {
                required: true,
                minlength: 15,
                maxlength: 16
            },
            CVV2: "required",
            expmonth: "required",
            expyear: "required",
            coupon: {
                required: false
            }
        },
        messages: {
            fname: "Please enter your first name",
            lname: "Please enter your last name",
            email: {
                required: "Please enter your email address",
                pattern: "Please enter a valid email address"
            },
            email_confirmation: {
                required: "Please enter your email address",
                pattern: "Please enter a valid email address",
                equalTo: "Please ensure the email fields match"
            },
            phone: "Please enter phone number",
            billing_address: "Please enter address",
            billing_country: "Please select a country",
            billing_city: "Please enter your city",
            billing_zip: "Please enter your zip code",
            billing_postal_code: "Please enter postal code",
            billing_state: "Please select state",
            billing_region: "Please enter region",
            shipping_address: "Please enter your shipping address",
            shipping_country: "Please select a country",
            shipping_city: "Please enter your shipping city",
            shipping_zip: "Please enter your zip code",
            shipping_postal_code: "Please enter a valid Postal code",
            shipping_state: "Please select your state",
            shipping_region: "Please enter a valid region",
            ccnum: {
              required: "Please enter card number",
              maxlength: "Enter the Number of Your Card Without Spaces Or Dashes"
            },
            CVV2: "Please enter security code",
            expmonth: "Please select expiration month",
            expyear: "Please select expiration year",
            cczipcode: "Please enter postal code"
        }
    });
});

angular.module('samcart')
    .directive('checkoutFormContainer', ['$rootScope', 'Checkout', function($rootScope, Checkout) {
        return {
            restrict: 'C',
            scope: { checkoutState:'=' },
            link: function(scope, el, attrs) {

                var visible = true;

                Checkout.listen( function() {

                    if (visible) {
                        visible = false;
                        el.addClass('animated fadeOut');
                        el.one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', 
                            function() {
                                Checkout.processing();
                            });
                    }

                }, "checkout_dismissed" );
            }
        };
    }]);
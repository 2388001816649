angular.module('samcart', [
    'samcart.directives',
    'samcart.controllers',
    'samcart.filters',
    'samcart.factories'
]);
angular.module('samcart.controllers', []);
angular.module('samcart.directives', []);
angular.module('samcart.filters', []);
angular.module('samcart.factories', []);

angular.module('samcart.factories')
    .factory('Order', [ '$http', function($http) {
        var Order = {

            capture: function(orderData, checkoutContext) {
                // Server is expecting the order data at the root, so to avoid breaking current checkouts we'll nest the
                // order into itself along with the checkout context.  Once the server has been updated to expect
                // {context: ..., order: ...} and all old clients have expired, we can remove the need to extend
                var payload = _.extend({}, orderData, {context: checkoutContext, order: orderData});
                return $http.post('/api/checkout/capture-order', payload).then(function(response) {
                    return response.data
                });
            },

            retry: function(order_data, callback) {
                $http.post('/api/checkout/try-new-card', order_data).success(function(data) {
                    callback(data);
                });
            },

            acceptUpsell: function(upsell_token, cart_token, scaReferenceId, product_id, upsellCustomFields, isGift, callback) {
                $http.post('/api/checkout/upsell/accept', {
                    cart_token: cart_token,
                    upsell_token: upsell_token,
                    product_id: product_id,
                    upsellCustomFields: upsellCustomFields,
                    sca_reference_id: scaReferenceId,
                    is_gift: isGift
                }).success(function(data){
                    callback(data);
                });
            },

            declineUpsell: function(upsell_token, cart_token, callback) {
                $http.post('/api/checkout/upsell/decline', {"cart_token" : cart_token, "upsell_token" : upsell_token}).success(function(data){
                    callback(data);
                });
            },

            getShippingTotalsForCountry: function(country_id, product_id, quantity, cart_id, sandbox, tax_country_id, order_bump_id, coupon_code, fn) {
                $http.post('/api/product/get_shipping_totals_for_country', { country_id: country_id, product_id: product_id, quantity: quantity, cart_id: cart_id, sandbox: sandbox, tax_country_id: tax_country_id, order_bump_id: order_bump_id, coupon_code: coupon_code }).success(function(data){
                    fn(data);
                });
            },

        };
        return Order;
    }]);

angular.module('samcart')
    .directive('funnelItem', ['$rootScope', '$compile', 'Checkout', function($rootScope, $compile, Checkout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {

                var center_modal = function(element) {
                    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
                    var height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                    var x_pos = (width - element.width()) / 2; 
                    if (x_pos < 0) x_pos = 0;
                    var y_pos = (height - element.height()) / 2;
                    if (y_pos < 0) y_pos = 0;
                    element.css({"left" : x_pos + 'px', "top" : y_pos + 'px', "width" : "100%", "height" : 
                    "auto", "margin" : "0 auto", "max-width" : "800px" });
                }

                var set_template = function(template) {
                    el.html(template);
                    $compile(el.contents())(scope);
                    center_modal(el);
                }

                Checkout.listen(function(template) {
                    set_template(template);
                    el.show();
                }, "show_funnel_item");

                Checkout.listen(function() {
                    Checkout.processing();
                    el.hide();

                }, "hide_funnel_item");

            }
        };
    }]);

function StripeElementsProvider() {
  this.$get = function () {
    if (typeof (Stripe) !== 'undefined') {
      // Vanilla Stripe tokenization (direct charges):
      // Use the marketplace's Stripe account ID in stripe.js instantiation
      // to specify that the PaymentMethod should be created on the connected account
      return Stripe(window.samcart.stripeKey, {
        stripeAccount: window.samcart.connectedStripeAccountId,
        betas: ['elements_enable_deferred_intent_beta_1'],
      });
    }
  };
}

function stripeElementDecorator() {
  return {
    restrict: 'A',
    link: link
  };

  function link($scope, $element) {
    var $ctrl = $scope.$ctrl;
    $ctrl.instance.mount($element[0]);
  }
}

function getStripeElementComponent() {
  return {
    template: '<div stripe-element-decorator></div>',
    controller: function () {
    },
    bindings: {
      instance: '<'
    }
  };
}

function TemplateStylesProvider() {
  this.$get = function () {
    return {
      accelerator: {
        base: {
          color: '#232427',
          fontWeight: 400,
          fontFamily: 'Roboto, sans-serif',
          fontSize: '18px',
          lineHeight: 1.458,

          '::placeholder': {
            color: '#b3b4b9',
            fontSize: '18px',
            fontWeight: 400,
          },
        },
        invalid: {
          color: '#FE435A'
        }
      },
      amyporterfield: {
        base: {
          color: '#7e7e7e',
          fontWeight: 400,
          fontFamily: 'Lato, Arial, Helvetica, sans-serif',
          fontSize: '18px',

          '::placeholder': {
            color: '#7e7e7e',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      blocky: {
        base: {
          color: '#414b55',
          fontWeight: 400,
          fontFamily: 'Raleway, sans-serif',
          fontSize: '14px',

          '::placeholder': {
            color: '#8c9eaf',
            fontSize: '13px',
            fontWeight: 500
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      bluepro: {
        base: {
          color: '#777',
          fontWeight: 600,
          fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#b6b6b6',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      businessclass: {
        base: {
          color: '#333',
          fontWeight: 300,
          fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#333',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      classic: {
        base: {
          color: '#333',
          fontWeight: 400,
          fontFamily: 'Lato, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#333',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      cleanblue: {
        base: {
          color: '#555',
          fontWeight: 300,
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: '14px',

          '::placeholder': {
            color: '#555',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      coursesthatconvert: {
        base: {
          color: '#7e7e7e',
          fontWeight: 400,
          fontFamily: 'Lato, Arial, Helvetica, sans-serif',
          fontSize: '18px',

          '::placeholder': {
            color: '#7e7e7e',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      formula: {
        base: {
          color: '#515151',
          fontWeight: 300,
          fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#515151',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      launchpad: {
        base: {
          color: '#232427',
          fontWeight: 400,
          fontFamily: 'Roboto, sans-serif',
          fontSize: '16px',
          lineHeight: 1.458,

          '::placeholder': {
            color: '#80868B',
            fontSize: '16px',
            fontWeight: 400,
          },
        },
        invalid: {
          color: '#FE435A'
        }
      },
      machine: {
        base: {
          color: '#555',
          fontWeight: 300,
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: '14px',

          '::placeholder': {
            color: '#A9A9A9',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      marketer: {
        base: {
          color: '#555',
          fontWeight: 600,
          fontFamily: 'Open Sans Condensed, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      multistep: {
        base: {
          color: '#1D1E1E',
          fontWeight: 400,
          fontFamily: 'Open Sans, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#8c9eaf',
          },
        },
        invalid: {
          color: '#FE435A'
        }
      },
      onepagefunnel: {
        base: {
          color: '#000000',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      popup: {
        base: {
          color: '#000000',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      popupv2: {
        base: {
          color: '#000000',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      popupsalesletter: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      salesletter: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      salesletternoheader: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      saleslettertwocolumn: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      saleslettertwocolumnright: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      showcase: {
        base: {
          color: '#555',
          fontWeight: 300,
          fontFamily: 'Open Sans Semibold, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#888',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      },
      startup: {
        base: {
          color: '#1D1E1E',
          fontWeight: 400,
          fontFamily: 'Roboto, sans-serif',
          fontSize: '18px',

          '::placeholder': {
            color: '#6c757d',
          },
        },
        invalid: {
          color: '#FE435A'
        }
      },
      webinarsthatconvert: {
        base: {
          color: '#7e7e7e',
          fontWeight: 400,
          fontFamily: 'Lato, Arial, Helvetica, sans-serif',
          fontSize: '18px',

          '::placeholder': {
            color: '#7e7e7e',
          },
        },
        invalid: {
          color: '#ff0000'
        },
      },
      worldwide: {
        base: {
          color: '#98A7B4',
          fontWeight: 300,
          fontFamily: 'TextaAlt, Arial, Helvetica, sans-serif',
          fontSize: '16px',

          '::placeholder': {
            color: '#98A7B4',
          },
        },
        invalid: {
          color: '#ff0000'
        }
      }
    }
  };
}

function PlaceholderProvider() {
  this.$get = function () {
    return {
      classic: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        postalCode: ''
      },
      cleanblue: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        postalCode: ''
      },
      formula: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        postalCode: ''
      },
      marketer: {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        postalCode: ''
      }
    };
  };
}

angular
  .module('samcart')
  .provider('StripeElements', StripeElementsProvider)
  .provider('TemplateStyles', TemplateStylesProvider)
  .provider('Placeholders', PlaceholderProvider)
  .directive('stripeElementDecorator', stripeElementDecorator)
  .component('stripeElement', getStripeElementComponent());

angular.module('samcart')
    .controller('checkoutController', ['$http', '$scope', '$document', 'Context',
        'Order', 'Checkout', 'Prospect', 'Coupon',
        '$location', 'Braintree', 'StripeElements', 'TemplateStyles',
        'Placeholders', '$filter', 'EasyPayDirect', 'EasyPayTemplateStyles',
        function ($http, $scope, $document, Context, Order,
                  Checkout, Prospect, Coupon, $location, Braintree,
                  StripeElements, TemplateStyles, Placeholders, $filter, EasyPayDirect, EasyPayTemplateStyles) {

            // SET VARIABLES TO SCOPE
            $scope.prospect = (typeof prospect !== 'undefined') ? prospect : null;
            $scope.visitor_token = (typeof visitorToken !== 'undefined') ? visitorToken : null;
            $scope.sandbox = (typeof sandbox !== 'undefined') ? sandbox : null;
            $scope.processor_selection = (typeof defaultProcessor !== 'undefined') ? defaultProcessor : null;
            $scope.upsellConfirmation = false;
            $scope.isUpsellGift = false;
            $scope.upsell = {
                product_id: "",
            };
            $scope.default_order_bump_settings = {
                "bgcolor": "#FFEDE1",
                "text_color": "#172F44",
                "checkbox_color": "#FF7A50",
                "border_color": "#FEC29C",
                "text_alignment": "left",
                "border_type": "none",
                "price_color": "#FF7A50",
                "checkbox_text": "Add to order",
                "headline_color": "#0E6FCC",
                "btn_text_color": "#FFFFFF",
                "checkbox_text_color": "#021B31",
            };
            $scope.bumpAdded = false;
            $scope.shouldShowSlideIn = true;
            $scope.buttonOnlyWidgets = ["slidein", "footer"];
            $scope.includesStickyBumpWidget = false;
            $scope.order_bump_dismissed_prefix = 'SAMCART_CHECKOUT_BUMP_DISMISSED_';
            $scope.cardNumberError = "";
            $scope.cardExpiryError = "";
            $scope.cardCvcError = "";
            $scope.isVerifyZipCode = false;
            $scope.step1active = true;
            $scope.step2active = false;
            $scope.step3active = false;
            $scope.isCouponValid = false;
            $scope.useMultipleOrderBumps = false;
            $scope.selected_product_id = null;
            $scope.original_product_id = null;

            $scope.customer_pricing = {};
            $scope.customer_pricing.visible = false;
            $scope.customer_pricing.suggested_price = '';
            $scope.customer_pricing.minimum = '';
            $scope.customer_pricing.name_your_price_text = '';
            $scope.customer_pricing.minimum_text = '';
            $scope.customer_pricing.title = '';
            $scope.customer_pricing.currency = Context.store.currency;
            $scope.zipCodeError = false;
            $scope.zipCodeErrorMessage = '';
            $scope.zipCodeErrorDisplayCount = 0;
            $scope.noInventory = false;

            var formatAddressFromProspect = function (prospect_address) {
                var address = {
                    address: prospect_address.address_line1,
                    address_line1: prospect_address.address_line1,
                    address_line2: prospect_address.address_line2,
                    country: prospect_address.country_id,
                    city: prospect_address.city,
                    region: prospect_address.region,
                    postal: prospect_address.postal_code,

                };
                return address;
            };

            $scope.captureProspect = function () {
                Prospect.data($scope.prospect, $scope.order, $scope.visitor_token, $scope.sandbox, function (prospect) {
                    $scope.prospect = prospect;
                });
            };

            $scope.coupon_code = '';
            $scope.cart_coupon = {code: '', text: ''};
            $scope.coupon_message = {text: '', error: false, success: false};
            $scope.selected_product = "product";
            $scope.one_page_funnel_product = window.product;
            $scope.tog = 1;
            $scope.show_loading_totals = false;
            $scope.order_bump_checked = false;
            $scope.hide_order_bump = false;
            $scope.hasPaymentOptions = false;
            $scope.createPaymentElement = true;
            $scope.refreshPaymentElement = true;
            $scope.sampaySubscriptionCount = 0;
            $scope.upsellCustomFields = {};
            $scope.scaReferenceId = null;
            $scope.order_bumps = new Set();
            $scope.shippingCountryIsRestricted = false;
            $scope.giftedProducts = {};
            $scope.showGiftInformation = false;
            $scope.isProcessingOrder = false;
            $scope.includes_gift = false;

            $scope.order = {
                customer: ($scope.prospect) ? $scope.prospect : {},
                shipping_address: ($scope.prospect && $scope.prospect.shipping_address) ? formatAddressFromProspect($scope.prospect.shipping_address) : {},
                billing_address: ($scope.prospect && $scope.prospect.billing_address) ? formatAddressFromProspect($scope.prospect.billing_address) : {},
                variation_id: angular.element("#variation_id").val(),
                quantity: "1",
                visitor_token: $scope.visitor_token,
                processor_type: $scope.processor_selection,
                sandbox: $scope.sandbox,
                referrer: document.referrer,
            };

            $scope.displayMoney = function (value) {
                return Math.abs(value / 100).toFixed(2);
            };

            $scope.coupon_amount = '';

            var determineProductId = function (product) {
                if (!product.use_ab_test || product.ab_test_id === 0) {
                    return product.id;
                }
                if (product.variation.parent_product_id === null) {
                    return product.id;
                }
                return product.variation.parent_product_id;
            };

            if (typeof product !== 'undefined') {
                $scope.checkoutState = {
                    currentCoupon: null,
                    product: product,
                    attached: attached,
                    bump: bump,
                    productPrice: product.price,
                    productSubPrice: (product.subscription_plan != null) ? product.subscription_plan.price : 0,
                    productCouponPrice: 0.00,
                    productSubCouponPrice: 0.00,
                    attachedPrice: (attached != null) ? attached.price : 0,
                    attachedSubPrice: (attached != null && attached.subscription_plan != null) ? attached.subscription_plan.price : 0,
                    attachedCouponPrice: 0.00,
                    attachedSubCouponPrice: 0.00,
                    currentPrice: product.price,
                    quantity: 1,
                    isMulti: (("payment_option" in product.properties) && product.properties.payment_option !== '0'), // Whether the checkout page is using multiple payments or not
                    shipping: defaultShipping,
                    tax: defaultTax,
                    subTotal: product.price,
                    grandTotal: product.price + defaultShipping,
                };
                $scope.order.product_id = determineProductId(product);
                $scope.order.product_variation_id = product.id;
                $scope.order.original_product_id = determineProductId(product);

                // This handles when max_quantity isn't set or if it's string '0'. Minimum is always 1.
                $scope.max_quantity_range = (Number(product.properties['max_quantity']) || 1);
                $scope.isVerifyZipCode = product.properties.zip_code;
                $scope.isVerifyRecaptcha = Context.store.use_recaptcha_v2;
            }

            firePurchase();

            angular.element('#placeOrder').click(function (e) {
                e.preventDefault();
            });

            var terms_required = false;
            if ((typeof product !== 'undefined') && product.properties.terms_checkbox && product.properties.terms_conditions) {
                $scope.order.terms_checked = false;
                terms_required = true;
            }

            $scope.placeOrder = function (visitation_token) {
                if ($scope.checkoutState.bump != null && $scope.checkoutState.bump.id !== 0 && $scope.order_bump_checked) {
                    $scope.order.bump_id = $scope.checkoutState.bump.id;
                } else {
                    $scope.order.bump_id = undefined;
                }

                if (terms_required && $scope.order.terms_checked === false) {
                    $scope.termsNotAccepted = true;
                } else {
                    $scope.termsNotAccepted = false;
                    if (!$scope.isProcessingOrder) {
                        $scope.isProcessingOrder = true;
                        checkout(visitation_token);
                    }
                }
                // $('.animated-label').each(function(){
                //   $(this).insertBefore($(this).prev('label'));
                // })
            };

            var hasStripeBillingValidationAndIsValid = function () {
                if (typeof stripeBillingValidation !== 'undefined' && $scope.processor_selection === 'cc_processor') {
                    return stripeBillingValidation.validate();
                }
                return true;
            };

            var checkout = function (visitation_token) {
                if ($("#paymentForm").valid() && hasStripeBillingValidationAndIsValid()) {
                    // Check for coupon
                    if ($scope.cart_coupon.code !== '') {
                        $scope.order.coupon_code = $scope.cart_coupon.code;
                    } else {
                        $scope.order.coupon_code = '';
                    }
                    // Parse CC month/year for new validation which uses string xx/yy
                    if (typeof stripeBillingValidation !== 'undefined' && $scope.processor_selection === 'cc_processor') {
                        var monthYear = $scope.order.card.exp_combo.split(" / ");
                        $scope.order.card.exp_month = monthYear[0];
                        $scope.order.card.exp_year = monthYear[1];
                    }
                    $scope.order.visitation_token = visitation_token;

                    prepSubmit().then(function () {
                        // Ensure checkout context is in sync (this can be removed once $scope.order is no longer used)
                        syncCheckoutContext();

                        // Check for credit card processor vs paypal
                        if ($scope.processor_selection === 'cc_processor' && $scope.checkoutContext.payment.isPaymentElementEnabled) {
                            Checkout.submitSamPay($scope.order, $scope.checkoutContext, $scope.sampayElements, function () {
                                fireAddPaymentInfo();
                                $scope.isProcessingOrder = false;
                            });
                        } else if ($scope.processor_selection === 'cc_processor') {
                            Checkout.submit($scope.order, $scope.checkoutContext, $scope.cardNumber, $scope.braintreeHostedFieldsInstance, function () {
                                fireAddPaymentInfo();
                                $scope.isProcessingOrder = false;
                            });
                        } else {
                            if ($scope.old_paypal) {
                                Checkout.submitPayPal($scope.order, $scope.checkoutContext, function (token) {
                                    fireAddPaymentInfo();
                                    var return_url = $("[name='notify_url']").val();
                                    var callback_url = $("[name='return']").val();
                                    return_url = return_url.substring(0, return_url.indexOf('/summary/') + 9) + token;
                                    callback_url = callback_url.substring(0, callback_url.indexOf('/api/paypal_callback/') + 21) + token;
                                    $("[name='notify_url']").val(callback_url);
                                    $("[name='return']").val(return_url);
                                    $('.paypal-button').submit();
                                });
                            } else {
                                window.scPayPal.hasPopupOpened = false;
                                if (window.scPayPal.observer) {
                                  window.scPayPal.observer.observe(
                                    document.documentElement || document.body,
                                    {childList: true, subtree: true}
                                  );
                                }

                                paypal.checkout.initXO();
                                Checkout.submitPayPalExpress($scope.order, $scope.checkoutContext, function (data) {
                                    fireAddPaymentInfo();
                                    if (typeof data.redirect === 'undefined' || data.redirect === '') {
                                        paypal.checkout.startFlow(paypal.checkout.urlPrefix + data.paypal_token);
                                    } else {
                                        window.location = data.redirect;
                                    }
                                });
                            }
                        }
                    }).catch(function (e) {
                        console.error(e);

                        var errorHeadline = "We're sorry but we could not process your order at this time."
                        var errorDetails = "Please try again or contact the seller for assistance."

                        var ua = navigator.userAgent || navigator.vendor || window.opera;
                        if (ua.indexOf('Instagram') > -1) {
                          errorDetails = "This may be due to technical issues with the Instagram web browser.<br><br>"
                            + "We recommend that you <b>open this page in your external browser</b> (Safari, Chrome, etc.) and try again.";
                        }

                        var errorModal = '<h1>' + errorHeadline + '</h1><p>' + errorDetails + '</p>';
                        Checkout.showErrorModal(errorModal);

                        $scope.isProcessingOrder = false;
                    });
                } else {
                    $scope.isProcessingOrder = false;
                }
            };

            function prepSubmit() {
                return new Promise(function (resolve, reject) {
                    if ($scope.isVerifyRecaptcha) {
                        resolve(fetchToken());
                    } else {
                        resolve('');
                    }
                });
            }

            var updateMultiPaymentPrices = function () {
                var state = $scope.checkoutState;

                var primary = $('.payment-option')[0];
                var attached = $('.payment-option')[1];
                if (state.currentCoupon != null) {
                    // Primary
                    $(primary).find('.sub-price').html($scope.displayMoney(state.productSubCouponPrice * state.quantity));
                    $(primary).find('.prod-price').html($scope.displayMoney(state.productCouponPrice * state.quantity));
                    $(primary).find('.product-price').html($scope.displayMoney(state.productCouponPrice * state.quantity));
                    // Attached
                    $(attached).find('.sub-price').html($scope.displayMoney(state.attachedSubCouponPrice * state.quantity));
                    $(attached).find('.product-price').html($scope.displayMoney(state.attachedCouponPrice * state.quantity));
                    $(attached).find('.prod-price').html($scope.displayMoney(state.attachedCouponPrice * state.quantity));
                } else {
                    // Primary
                    $(primary).find('.sub-price').html($scope.displayMoney(state.productSubPrice * state.quantity));
                    $(primary).find('.prod-price').html($scope.displayMoney(state.productPrice * state.quantity));
                    $(primary).find('.product-price').html($scope.displayMoney(state.productPrice * state.quantity));
                    // Attached
                    $(attached).find('.sub-price').html($scope.displayMoney(state.attachedSubPrice * state.quantity));
                    $(attached).find('.prod-price').html($scope.displayMoney(state.attachedPrice * state.quantity));
                    $(attached).find('.product-price').html($scope.displayMoney(state.attachedPrice * state.quantity));
                }
            };

            $scope.setPaymentType = function (selection) {
                $scope.processor_selection = selection;
                $scope.order.processor_type = selection;
            };

            $scope.retryCard = function (cart_token) {
                if ($("#paymentForm").valid() && hasStripeBillingValidationAndIsValid()) {
                    // Parse CC month/year for new validation which uses string xx/yy
                    if (typeof stripeBillingValidation !== 'undefined' && $scope.processor_selection == 'cc_processor') {
                        var monthYear = $scope.order.card.exp_combo.split(" / ");
                        $scope.order.card.exp_month = monthYear[0];
                        $scope.order.card.exp_year = monthYear[1];
                    }

                    $scope.order.cart_token = cart_token;
                    Checkout.retry($scope.order, $scope.cardNumber, $scope.braintreeHostedFieldsInstance);
                }
            };

            $scope.acceptUpsell = function (upsell_token, cart_token, customFieldValues) {
                $scope.expire = false;
                var product_id = $scope.upsell.product_id;

                if (!customFieldValues) {
                    customFieldValues = [];
                }

                if ($scope.isUpsellGift) {
                    var customFieldValuesParsed = JSON.parse(customFieldValues);
                    customFieldValuesParsed.forEach(function (item) {
                        $scope.upsellCustomFields[item.custom_field.slug] = item['value'];
                    });
                }

                if ($("#upsellForm").length && $("#upsellForm").valid()) {
                    Checkout.acceptUpsell(upsell_token, cart_token, $scope.scaReferenceId, product_id, $scope.upsellCustomFields, $scope.isUpsellGift);
                } else if (!$("#upsellForm").length) {
                    Checkout.acceptUpsell(upsell_token, cart_token, $scope.scaReferenceId, product_id, $scope.upsellCustomFields, $scope.isUpsellGift);
                }
            };

            $scope.shouldRenderGiftCheckbox = function (cartItems, customFieldValues, isUpsellGift) {
                var cartItemsParsed = JSON.parse(cartItems);
                var isAnyCartItemGift = false;
                var mandatoryGiftCustomFields = [
                    'Gift_recipient_first_name',
                    'Gift_recipient_last_name',
                    'Gift_recipient_email_address',
                ];
                var optionalGiftCustomField = 'Gift_recipient_message'

                for (var i = 0; i < cartItemsParsed.length; i++) {
                    if (cartItemsParsed[i].is_gift === true) {
                        isAnyCartItemGift = true;
                        break;
                    }
                }

                if (isAnyCartItemGift && customFieldValues) {
                    var customFieldValuesParsed = JSON.parse(customFieldValues);
                    var hasGiftRecipientData = customFieldValuesParsed.map(function (item) {
                        return !!((mandatoryGiftCustomFields.indexOf(item.custom_field.name) >= 0 || item.custom_field.name === optionalGiftCustomField) && item.value.length);
                    });

                    var trueCounter = 0;
                    for (var i = 0; i < hasGiftRecipientData.length; i++) {
                        if (hasGiftRecipientData[i]) {
                            trueCounter += 1;
                        }
                    }

                    return !!isUpsellGift && trueCounter === mandatoryGiftCustomFields.length;
                }

                return isAnyCartItemGift;
            }

            $scope.declineUpsell = function (upsell_token, cart_token) {
                $scope.expire = false;
                Checkout.declineUpsell(upsell_token, cart_token);
            };

            $scope.hideErrorModal = function () {
                Checkout.hideErrorModal();
            };

            $scope.billingCountrySelected = function (product_id, cart_id, sandbox) {
                if ($scope.has_vat) {
                    $scope.updateShippingCosts(product_id, cart_id, sandbox);
                }
            };

            $scope.updateShippingCosts = function (physical_product_id, cart_id, sandbox) {
                // Should refactor this so physical_product_id isn't needed from the templates
                if ($scope.order.quantity == null || $scope.order.quantity == '') {
                    return;
                }

                if ($scope.max_quantity_range && $scope.order.quantity > $scope.max_quantity_range) {
                  $scope.order.quantity = $scope.max_quantity_range;
                }

                // On the off chance max_quantity_range got set to 0 somehow, do that check before this one.
                // The minimum quantity is always one.
                if ($scope.order.quantity < 1) {
                    $scope.order.quantity = 1;
                }

                physical_product_id = $scope.order.product_id;
                $scope.show_loading_totals = true;
                if (!physical_product_id) {
                    var physical_product_id = $scope.checkoutState.product.id;
                }
                var country_id = $scope.order.shipping_address.country;
                var quantity = $scope.order.quantity;
                var coupon_code = $scope.coupon_code;

                // ORDER BUMP ADDED
                if ($scope.checkoutState.bump && $scope.order_bump_checked) {
                    var order_bump_id = $scope.checkoutState.bump.id;
                } else {
                    var order_bump_id = null;
                }

                // IF HAD VAT AND BILLING ADDRESS WE NEED TO SET THE TAX COUNTRY SEPARATELY
                if ($scope.has_vat && $scope.order.billing_address.country) {
                    var tax_country_override_id = $scope.order.billing_address.country;
                } else {
                    var tax_country_override_id = null;
                }

                $scope.checkoutState.quantity = quantity;
                Order.getShippingTotalsForCountry(country_id, physical_product_id, quantity, cart_id, sandbox, tax_country_override_id, order_bump_id, coupon_code, function (data) {
                    $scope.checkoutState.shipping = parseFloat(data['shipping']) * 100;
                    $scope.checkoutState.tax = parseFloat(data['tax']) * 100;
                    $scope.show_loading_totals = false;

                    setCheckoutPrice();
                });

                refreshCheckoutContext();
            };

            $scope.use_shipping = false;

            $scope.updateBilling = function () {
                if ($scope.use_shipping) {
                    $scope.order.shipping_address.address = $scope.order.billing_address.address;
                    $scope.order.shipping_address.country = $scope.order.billing_address.country;
                    $scope.order.shipping_address.city = $scope.order.billing_address.city;
                    $scope.order.shipping_address.region = $scope.order.billing_address.region;
                    $scope.order.shipping_address.postal = $scope.order.billing_address.postal;
                }
            };

            $scope.countries = Context.store.countries;
            $scope.has_vat = Context.store.has_vat;

            $scope.toggleBumpTotal = function () {
                setCheckoutPrice();
            };

            /******************
             This method stores the original prices of the primary and attached products for future use.
             Author: Jake
             ******************/
            var setOriginalPrices = function (state) {
                if ($scope.checkoutState.isMulti) {
                    var primary = $('.payment-option')[0];
                    state.primaryOriginalPrice = $(primary).find('.pricing-text').attr('prod-price');
                    state.primaryOriginalSubPrice = $(primary).find('.pricing-text').attr('sub-price');
                    // For the attached product, pull the price from the 2nd payment option html
                    var attached = $('.payment-option')[1];
                    state.attachedOriginalPrice = $(attached).find('.pricing-text').attr('prod-price');
                    state.attachedOriginalSubPrice = $(attached).find('.pricing-text').attr('sub-price');
                } else {
                    state.primaryOriginalPrice = $('.price').find('.product-price').html();
                    state.primaryOriginalSubPrice = $('.price').find('.subscription-price').html();
                }
            };

            /******************
             This method is called via an ng-init in the template php file.
             This method sets the product_id and gives this controller access to it.
             Author: Jake
             ******************/
            $scope.setProductId = function (id, attachedId) {
                $scope.order.product_id = id;
                $scope.order.original_product_id = id;
                $scope.checkoutState.attachedProductId = attachedId;
            };

            // This method is called in the shared php file sandbox-header.blade.php so that it is incorporated by all templates
            $scope.setMulti = function (status) {
                $scope.checkoutState.isMulti = status;
            }

            /******************
             This method checks the coupon code for a discount.
             This method will send a different backend request based on whether the page has multiple payments or not.
             This method is called from the checkout page when the apply coupon button is clicked.
             ******************/
            $scope.checkCoupon = function (product_id, cart_id, sandbox) {
                if (!$scope.coupon_code) {
                  return;
                }

                refreshCheckoutContext();

                var state = $scope.checkoutState;
                if (state.isMulti) {
                    Coupon.checkMultiple({
                        coupon_code: $scope.coupon_code,
                        product_id: $scope.checkoutState.product.original_product.id,
                        attached_id: state.attached.id,
                        original_product_id: $scope.order.original_product_id,
                        currency: $scope.checkoutContext.cart.settings.currency_iso,
                        email: $scope.checkoutContext.cart.customer.email,
                    }, function (data) {
                        if (data.success === true) {
                            state.currentCoupon = data;
                            $scope.isCouponValid = true;
                            $scope.coupon_amount = state.currentCoupon.data.display_text;
                        } else {
                            state.currentCoupon = null;
                            $scope.isCouponValid = false;
                            $scope.coupon_message.text = 'Coupon ' + $scope.coupon_code + ' is invalid.';
                            $scope.coupon_message.error = true;
                            $scope.coupon_message.success = false;
                            $scope.coupon_amount = '';
                        }
                        applyCouponToPrice(state);
                        $scope.updateShippingCosts(product_id, cart_id, sandbox);
                    });
                } else {
                    // Checks to see if the coupon code is valid and applies the discount
                    Coupon.check({
                        coupon_code: $scope.coupon_code,
                        product_id: $scope.checkoutState.product.original_product.id,
                        currency: $scope.checkoutContext.cart.settings.currency_iso,
                        original_product_id: $scope.order.original_product_id,
                        email: $scope.checkoutContext.cart.customer.email,
                    }, function (data) {
                        if (data.success === true) {
                            state.currentCoupon = data;
                            $scope.isCouponValid = true;
                            $scope.coupon_amount = state.currentCoupon.data.display_text;
                        } else {
                            state.currentCoupon = null;
                            $scope.isCouponValid = false;
                            $scope.coupon_message.text = data.error ? data.error : 'Coupon ' + $scope.coupon_code + ' is invalid.';
                            $scope.coupon_message.error = true;
                            $scope.coupon_message.success = false;
                            $scope.coupon_amount = '';
                        }
                        applyCouponToPrice(state);
                        $scope.updateShippingCosts(product_id, cart_id, sandbox);
                    });
                }
            };

            $scope.resolveHeaderColor = function (cssTarget) {

                if (product.properties.headercolor_value && product.properties.headercolor_custom == 1) {
                    var headerColor = product.properties.headercolor_value;
                    return JSON.parse('{"' + cssTarget + '": "' + headerColor + '"}');
                }
                return false;
            };
            $scope.resolveHeadlineColor = function (cssTarget) {

                if (product.properties.hlcolor_value && product.properties.hlcolor_custom == 1) {
                    var headlineColor = product.properties.hlcolor_value;
                    return JSON.parse('{"' + cssTarget + '": "' + headlineColor + '"}');
                }
                return false;
            };

            $scope.resolveOrderBumpBackgroundColor = function () {
                var color = $scope.default_order_bump_settings.bgcolor;
                if (product.properties.order_bump_settings) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.has_bgcolor) {
                        color = settings.bgcolor;
                    }
                }
                return color;
            };

            $scope.resolveOrderBumpTextColor = function () {
                var color = $scope.default_order_bump_settings.text_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.has_text_color) {
                        color = settings.text_color;
                    }
                }
                return color;
            }

            $scope.resolveOrderBumpHeadlineColor = function () {
                var color = $scope.default_order_bump_settings.headline_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (typeof settings.has_headline_color === 'undefined') {
                        color = $scope.resolveOrderBumpTextColor();
                    } else if (settings.has_headline_color === true) {
                        color = settings.headline_color;
                    }
                }
                return color;
            }

            $scope.resolveOrderBumpButtonTextColor = function () {
                var color = $scope.default_order_bump_settings.btn_text_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.has_btn_text_color) {
                        color = settings.btn_text_color;
                    } else if (settings.button_type !== "rounded_button" && !$scope.buttonOnlyWidgets.indexOf(settings.template) !== -1) {
                        color = $scope.default_order_bump_settings.checkbox_text_color;
                    }
                }
                return color;
            }

            $scope.resolveCheckboxBackgroundColor = function () {
                var color = $scope.default_order_bump_settings.checkbox_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.has_price_color) {
                        color = settings.price_color;
                    }
                }
                return color;
            }

            $scope.resolveOrderBumpPriceColor = function () {
                var color = $scope.default_order_bump_settings.price_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.has_price_color) {
                        color = settings.price_color;
                    }
                }
                return color;
            }

            $scope.resolveOrderBumpTextAlignment = function () {
                var alignment = $scope.default_order_bump_settings.text_alignment;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    alignment = settings.text_alignment;
                }
                return alignment;
            }

            $scope.resolveOrderBumpBorderStyle = function () {
                return '2px ' + $scope.resolveOrderBumpBorderType() + ' ' + $scope.resolveOrderBumpBorderColor();
            }

            $scope.resolveOrderBumpBorderColor = function () {
                var color = $scope.default_order_bump_settings.border_color;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings)
                    if (settings.has_border_color) {
                        color = settings.border_color;
                    }
                }
                return color
            }

            $scope.resolveOrderBumpBorderType = function () {
                var alignment = $scope.default_order_bump_settings.border_type;
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    alignment = settings.border;
                }
                return alignment;
            }

            $scope.resolveOrderBumpCheckboxText = function () {
                if (product.properties.order_bump_settings !== undefined) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (settings.checkbox_text) {
                        return settings.checkbox_text;
                    } else {
                        return $scope.default_order_bump_settings.checkbox_text
                    }
                }
                return $scope.default_order_bump_settings.checkbox_text;
            }

            $scope.getContainerWidth = function (selector) {
                return $(selector).first().width();
            }

            $scope.getContentContainerInnerHeight = function (selector) {
                return $(selector).find('.header-content').innerHeight() + $(selector).find('.bump-content').innerHeight();
            }

            $scope.deleteCoupon = function () {
                $scope.coupon_amount = '';
                $scope.cart_coupon.code = '';
                $scope.cart_coupon.text = '';
                $scope.coupon_message.text = '';
                $scope.coupon_message.error = false;
                $scope.coupon_message.success = false;
                $scope.order.coupon_id = '';
                $scope.checkoutState.currentCoupon = null;
                $scope.coupon_code = null;
                $scope.isCouponValid = false;
                setCheckoutPrice();
                refreshCheckoutContext();
            };

            $scope.onEmailBlur = function () {
              if ($scope.coupon_code) {
                $scope.checkCoupon($scope.order.product_id, false, $scope.sandbox);
              }
            };

            /****************
             Multiple Payment Selection Option - Set's which product to use
             Author: ???
             ****************/
            $scope.setProduct = function (product_type, id) {
                var state = $scope.checkoutState;

                if ($scope.order.original_product_id === 0) {
                    $scope.order.original_product_id = $scope.order.product_id;
                }

                if ($scope.includes_gift) {
                    initProductIsGift(id);
                    removeProductIsGift($scope.selected_product_id);
                }

                $scope.selected_product = product_type;
                $scope.one_page_funnel_product = product_type;
                $scope.checkoutContext.cart.settings.selected_payment_option = id;
                $scope.order.product_id = id;
                $scope.selected_product_id = id;

                if (product_type === 'attached_product' && typeof product_options[0].attached_product.bundled_products !== 'undefined') {
                    setProductBundles(product_options[0].attached_product.bundled_products);
                } else {
                    if (typeof product.properties.bundled_products !== 'undefined') {
                        setProductBundles(JSON.parse(product.properties.bundled_products));
                    }
                }

                // Will be used when we redo shipping - JAKE
                // $scope.updateShippingCosts(null, false, $scope.sandbox);
                $scope.updateShippingCosts(id, false, $scope.sandbox);

                setCheckoutPrice();
            }
            $scope.updateNameYourOwnPrice = function () {
                syncNameYourOwnPrice();
                refreshCheckoutContext();
            };

            /****************
             Product Options, changes most of the page to reflect the new product selected
             Author: Bubba
             ****************/
            $scope.setProductOption = function (text, id) {
                var state = $scope.checkoutState;

                //not sure?
                if ($scope.order.original_product_id === 0) {
                    $scope.order.original_product_id = $scope.order.product_id;
                }

                if ($scope.includes_gift) {
                    initProductIsGift(id);
                    removeProductIsGift($scope.selected_product_id);
                }

                $scope.order.product_id = id;
                $scope.selected_product_id = id;
                $scope.checkoutContext.cart.settings.selected_payment_option = id;

                $.each(product_options, function (index, value) {
                    if (value.product.id == id) {
                        $scope.setCustomProduct(value);
                        $scope.checkCoupon();
                    }
                });
                $scope.updateShippingCosts(id, false, $scope.sandbox);

                showCustomerPricing();
            };

            $scope.setCustomProduct = function (product_option) {
                $scope.selected_product = product_option.product;
                $scope.selected_product_id = product_option.product.id;
                $scope.one_page_funnel_product = product_option.product;
                $scope.checkoutState.productPrice = $scope.selected_product.price;
                $scope.checkoutState.product.original_product.id = $scope.selected_product.id;
                $('.payment-opt').removeClass('selected');
                $('.payment-opt').addClass('not-selected');

                $('#product-option-' + $scope.selected_product.id).removeClass('not-selected');
                $('#product-option-' + $scope.selected_product.id).addClass('selected');
                if (typeof product_option.product.properties.bundled_products !== 'undefined') {
                    setProductBundles(JSON.parse(product_option.product.properties.bundled_products));
                }
                setCheckoutPrice();

                var purchaseButton = document.getElementById("placeOrder");
                if (product_option.product && product_option.product.outOfStock) {
                    $scope.noInventory = product_option.product.outOfStock;
                    if (purchaseButton) {
                        purchaseButton.innerHTML = product_option.product.outOfStockDisclaimer;
                    }
                } else {
                    $scope.noInventory = false;
                    if (purchaseButton) {
                        document.getElementById("placeOrder").innerHTML = product_option.product.properties.checkout_button_text || 'Place Order Now';
                    }
                }
            }

            /****************
             Applies the coupon to the product price
             Author: JAKE
             ****************/
            var applyCouponToPrice = function () {
                var state = $scope.checkoutState;

                if (state.currentCoupon != null) {
                    setCouponInfo();
                    if (state.isMulti) {
                        state.productCouponPrice = state.currentCoupon.data[0].one_time_price * 100;
                        state.productSubCouponPrice = state.currentCoupon.data[0].recurring_price * 100;
                        state.attachedCouponPrice = state.currentCoupon.data[1].one_time_price * 100;
                        state.attachedSubCouponPrice = state.currentCoupon.data[1].recurring_price * 100;
                    } else {
                        state.productCouponPrice = state.currentCoupon.data.one_time_price * 100;
                        state.productSubCouponPrice = state.currentCoupon.data.recurring_price * 100;
                    }
                }
                setCheckoutPrice();
            };

            /***********
             * Is the primary product selected (for multiple payments),
             * if single payment, always true
             */
            var isPrimarySelected = function () {
                if (product_options.length > 1) {
                    //hack this because we are using product options and not attached products :(
                    return true;
                }
                if ($scope.checkoutState.isMulti) {
                    if ($scope.order.product_id == $scope.order.original_product_id || $scope.order.original_product_id == 0) {
                        return true;
                    } else if ($scope.order.product_id == $scope.checkoutState.attachedId) {
                        return false;
                    }
                } else {
                    return true;
                }
            }

            // Set coupon code and message flags
            var setCouponInfo = function () {
                var state = $scope.checkoutState;

                if (state.isMulti) {
                    $scope.cart_coupon.code = state.currentCoupon.data[0].coupon_code;
                } else {
                    $scope.cart_coupon.code = state.currentCoupon.data.coupon_code;
                }
                $scope.coupon_message.text = 'Coupon applied successfully to your Order.';
                $scope.coupon_message.error = false;
                $scope.coupon_message.success = true;
            };

            /****************
             Set's the prices of the products on the page.
             Sets both the multiple payment boxes prices and the total order price
             Hacky as shit still, needs to be refactored when we refactor the templates
             Author: JAKE, ROB
             ****************/
            var setCheckoutPrice = function () {
                var state = $scope.checkoutState;
                var orderBump = ($scope.order_bump_checked && (state.bump != null)) ? state.bump.price : 0.00;
                if (state.currentCoupon == null) {
                    state.currentPrice = (isPrimarySelected()) ? state.productPrice : state.attached.price;
                    state.subTotal = (state.currentPrice * state.quantity) + orderBump;
                    state.grandTotal = state.subTotal + state.shipping + state.tax;
                    if (!state.isMulti) {
                        if ($scope.order_bump_checked == true && state.bump.subscription_plan) {
                            $('.price .subscription-price').html($scope.displayMoney((state.productSubPrice + state.bump.subscription_plan.price) * state.quantity));
                        } else {
                            $('.price .subscription-price').html($scope.displayMoney(state.productSubPrice * state.quantity));
                        }
                    }
                } else {
                    state.currentPrice = (isPrimarySelected()) ? state.productCouponPrice : state.attachedCouponPrice;
                    state.subTotal = (state.currentPrice * state.quantity) + +orderBump;
                    state.grandTotal = state.subTotal + +state.shipping + state.tax;
                    if (!state.isMulti) {
                        if ($scope.order_bump_checked == true && state.bump.subscription_plan) {
                            $('.price .subscription-price').html($scope.displayMoney((state.productSubCouponPrice + state.bump.subscription_plan.price) * state.quantity));
                        } else {
                            $('.price .subscription-price').html($scope.displayMoney(state.productSubCouponPrice * state.quantity));
                        }
                    }
                }

                if (state.isMulti) {
                    updateMultiPaymentPrices();
                } else {
                    $(".grand-total").html($scope.displayMoney(state.grandTotal));
                    $(".product-subtotal").html($scope.displayMoney(state.subTotal));
                }
            };

            $scope.states = [
                {data: "AL", label: "Alabama"},
                {data: "AK", label: "Alaska"},
                {data: "AZ", label: "Arizona"},
                {data: "AR", label: "Arkansas"},
                {data: "AA", label: "Armed Forces America"},
                {data: "AE", label: "Armed Forces Europe"},
                {data: "AP", label: "Armed Forces Pacific"},
                {data: "CA", label: "California"},
                {data: "CO", label: "Colorado"},
                {data: "CT", label: "Connecticut"},
                {data: "DE", label: "Delaware"},
                {data: "DC", label: "District of Columbia"},
                {data: "FL", label: "Florida"},
                {data: "GA", label: "Georgia"},
                {data: "HI", label: "Hawaii"},
                {data: "ID", label: "Idaho"},
                {data: "IL", label: "Illinois"},
                {data: "IN", label: "Indiana"},
                {data: "IA", label: "Iowa"},
                {data: "KS", label: "Kansas"},
                {data: "KY", label: "Kentucky"},
                {data: "LA", label: "Louisiana"},
                {data: "ME", label: "Maine"},
                {data: "MD", label: "Maryland"},
                {data: "MA", label: "Massachusetts"},
                {data: "MI", label: "Michigan"},
                {data: "MN", label: "Minnesota"},
                {data: "MS", label: "Mississippi"},
                {data: "MO", label: "Missouri"},
                {data: "MT", label: "Montana"},
                {data: "NE", label: "Nebraska"},
                {data: "NV", label: "Nevada"},
                {data: "NH", label: "New Hampshire"},
                {data: "NJ", label: "New Jersey"},
                {data: "NM", label: "New Mexico"},
                {data: "NY", label: "New York"},
                {data: "NC", label: "North Carolina"},
                {data: "ND", label: "North Dakota"},
                {data: "OH", label: "Ohio"},
                {data: "OK", label: "Oklahoma"},
                {data: "OR", label: "Oregon"},
                {data: "PA", label: "Pennsylvania"},
                {data: "RI", label: "Rhode Island"},
                {data: "SC", label: "South Carolina"},
                {data: "SD", label: "South Dakota"},
                {data: "TN", label: "Tennessee"},
                {data: "TX", label: "Texas"},
                {data: "UT", label: "Utah"},
                {data: "VT", label: "Vermont"},
                {data: "VA", label: "Virginia"},
                {data: "WA", label: "Washington"},
                {data: "WV", label: "West Virginia"},
                {data: "WI", label: "Wisconsin"},
                {data: "WY", label: "Wyoming"},
            ];

            $scope.americanRegions = Context.store.regions['US'];
            $scope.canadianRegions = Context.store.regions['CA'];
            $scope.mexicanRegions = Context.store.regions['MX'];
            $scope.ukRegions = Context.store.regions['GB'];
            $scope.austrailianRegions = Context.store.regions['AU'];

            $scope.regionsForSelectedCountry = function (country) {
              switch (country) {
                case 840:
                  return $scope.americanRegions;
                case 124:
                  return $scope.canadianRegions;
                case 484:
                  return $scope.mexicanRegions;
                case 826:
                  return $scope.ukRegions;
                case 36:
                  return $scope.austrailianRegions;
              }
            }

            $scope.getRegionLabel = function (country) {
              if (typeof window.productLanguageSetting.region !== 'undefined' && window.productLanguageSetting.region !== '') {
                return window.productLanguageSetting.region
              }

              switch (country) {
                case 840:
                  return 'State';
                case 124:
                  return 'Province';
                default:
                  return 'Region';
              }
            }

            $scope.toggleOrderBump = function (product_id, cart_id, sandbox) {
                if ($scope.order_bump_checked == false) {
                    $scope.order_bump_checked = true;
                } else {
                    $scope.order_bump_checked = false;
                }

                $scope.updateShippingCosts(product_id, cart_id, sandbox);
                setCheckoutPrice();
            }

            $scope.toggleOrderBumps = function (product_id, order_bump_id, cart_id, sandbox) {
                $scope.useMultipleOrderBumps = true;
                if ($scope.order_bumps.has(order_bump_id)) {
                    $scope.order_bumps.delete(order_bump_id);
                } else {
                    $scope.order_bumps.add(order_bump_id);
                }

                $scope.updateShippingCosts(product_id, cart_id, sandbox);
                setCheckoutPrice();
            }

            $scope.addOrderBump = function (product_id, order_bump_id, cart_id, sandbox) {
                $scope.useMultipleOrderBumps = true;
                if (!$scope.order_bumps.has(order_bump_id)) {
                    $scope.order_bumps.add(order_bump_id);
                    $scope.bumpAdded = true;

                    if ($scope.includes_gift) {
                        initProductIsGift(order_bump_id);
                    }

                    $scope.updateShippingCosts(product_id, cart_id, sandbox);
                    setCheckoutPrice();
                }
            }

            $scope.closeOrderBump = function (product_id, order_bump_id) {
                if (!$scope.sandbox) {
                    var key = $scope.order_bump_dismissed_prefix + product_id;
                    var date = new Date();
                    date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
                    expires = "; expires=" + date.toUTCString();
                    document.cookie = key + "=" + order_bump_id + expires;
                }
                $scope.closeBump = true;
            }

            $scope.showSlideIn = function (product_id, order_bump_id) {
                if ($scope.sandbox) {
                    return true;
                }
                var key = $scope.order_bump_dismissed_prefix + product_id + "=" + order_bump_id;
                return !document.cookie.split(';').some(function (cookie) {
                    return cookie.indexOf(key) >= 0;
                });
            }

            if (typeof product !== 'undefined') {
                setCheckoutPrice();
            }

            $scope.lightenDarkenColor = function lightenDarkenColor(col, amt) {
                var usePound = false;
                if (col[0] == "#") {
                    col = col.slice(1);
                    usePound = true;
                }
                var num = parseInt(col, 16);
                var r = (num >> 16) + amt;
                if (r > 255) r = 255;
                else if (r < 0) r = 0;
                var b = ((num >> 8) & 0x00FF) + amt;
                if (b > 255) b = 255;
                else if (b < 0) b = 0;
                var g = (num & 0x0000FF) + amt;
                if (g > 255) g = 255;
                else if (g < 0) g = 0;

                return (usePound ? "#" : "") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
            };

            $scope.optionOneSelected = function optionOneSelected() {
                var headerColor = (product.properties.headercolor_value && product.properties.headercolor_custom == 1) ? product.properties.headercolor_value : "#ffd140";
                $('.amy-p-payment-option-one').css('background-color', headerColor);
                $('.amy-p-payment-option-two').css('background-color', '#ffffff');
            };
            $scope.optionTwoSelected = function optionTwoSelected() {
                var headerColor = (product.properties.headercolor_value && product.properties.headercolor_custom == 1) ? product.properties.headercolor_value : "#ffd140";
                $('.amy-p-payment-option-two').css('background-color', headerColor);
                $('.amy-p-payment-option-one').css('background-color', '#ffffff');
            };

            var queryString = function (key) {
                key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
                var match = location.search.match(new RegExp("[?&]" + key + "=([^&]+)(&|$)"));
                return match && decodeURIComponent(match[1].replace(/\+/g, " "));
            };

            $scope.showConfirmation = function () {
                $scope.upsellConfirmation = true;
            };

            $scope.toggleIsUpsellGift = function (isChecked) {
                $scope.isUpsellGift = !isChecked;
            }

            $scope.hasStickyBumpWidget = function (product) {
                var stickyWidgets = ["slidein", "footer"];
                if (product.properties.order_bump_settings) {
                    var settings = JSON.parse(product.properties.order_bump_settings);
                    if (stickyWidgets.indexOf(settings.template) !== -1) {
                        return true;
                    }
                }
                return false;
            }

            $scope.giftOrderInfo = function (productId) {
                if ($scope.giftedProducts[productId]) {
                    removeProductIsGift(productId);
                    $scope.includes_gift = !!Object.keys($scope.giftedProducts).length;
                } else {
                    initProductIsGift(productId);
                    $scope.includes_gift = true;
                }
            }

            $scope.togglePageLevelGifting = function () {
                $scope.includes_gift = !$scope.includes_gift;

                if (!$scope.includes_gift) {
                    $scope.giftedProducts = {};
                }
            }

            $scope.isGiftedProduct = function (productId) {
                return $scope.giftedProducts[productId];
            }
            $scope.beautifyPlaceOrderButton = function () {
                var ctaButton = $('.main-cta');
                if (ctaButton.css('background') !== "") {
                    $scope.originalButtonColor = ctaButton.css('background');
                    var percent = -0.1;
                    var f = $scope.originalButtonColor.split(","), t = percent < 0 ? 0 : 255,
                        p = percent < 0 ? percent * -1 : percent, R = parseInt(f[0].slice(4)), G = parseInt(f[1]),
                        B = parseInt(f[2]);
                    $scope.darkenColor = "rgb(" + (Math.round((t - R) * p) + R) + "," + (Math.round((t - G) * p) + G) + "," + (Math.round((t - B) * p) + B) + ")";

                    $('.main-cta').on('mouseover', function () {
                        $(this).css('background-color', $scope.darkenColor);
                        $(this).css('background', $scope.darkenColor);
                    });
                    $('.main-cta').on('mouseleave', function () {
                        $(this).css('background-color', $scope.originalButtonColor);
                        $(this).css('background', $scope.originalButtonColor);
                    });
                }
            }
            angular.element(document).ready(function () {

                if (typeof product === 'undefined') {
                    return;
                }

                if ($scope.processor_selection !== 'digital_wallet') {
                    $scope.beautifyPlaceOrderButton();
                }

                // Assigns headercolor_value; to the order-bump button and payment options buttons
                var headerColor = '';
                switch (product.properties.template) {
                    case "amyporterfield":
                        headerColor = "#FFD140";
                        break;
                    case "coursesthatconvert":
                        headerColor = "#E43349";
                        break;
                    case "webinarsthatconvert":
                        headerColor = "#20BEC7";
                        break;
                }
                headerColor = (product.properties.headercolor_value && product.properties.headercolor_custom == 1) ? product.properties.headercolor_value : headerColor;
                var headlineColor = (product.properties.hlcolor_value && product.properties.hlcolor_custom == 1) ? product.properties.hlcolor_value : null;

                $('.amy-p-bump').on('mouseover', function () {
                    $(this).css('background-color', headerColor);
                    $(this).css('border-color', headerColor);
                });
                $('.amy-p-bump').on('mouseleave', function () {
                    if (!$scope.order_bump_checked) {
                        $(this).css('background-color', '#ffffff');
                        $(this).css('border-color', '#cccccc');
                    }
                });

                if ($('.amy-p-num').length > 0) {
                    $('.amy-p-num').css('background-color', $scope.lightenDarkenColor(headerColor, -20)); //set number
                }
                if ($('.amy-p-payment-option-one').length > 0) {
                    $('.amy-p-payment-option-one').css('background-color', headerColor);
                }

                if (product_options.length > 1) {
                    $scope.hasPaymentOptions = true;
                    if (queryString('option')) {
                        var _product_option = product_options[parseInt(queryString('option')) - 1];
                        $scope.order.original_product_id = _product_option.product.id;
                        $scope.order.product_id = _product_option.product.id;
                        $scope.selected_product_id = _product_option.product.id;
                        $scope.original_product_id = _product_option.product.id;
                        $scope.setCustomProduct(_product_option);
                        refreshCheckoutContext();
                    } else {
                        $scope.setCustomProduct(product_options[0]);
                    }
                }

                // Check to see if a bundle flag is set and auto-associate order bump with order
                if (queryString('8AzvwARAilLM')) {
                    $scope.order_bump_checked = true;
                    $scope.hide_order_bump = true;
                    refreshCheckoutContext();
                }

                if ($scope.checkoutState.product && $scope.checkoutState.bump) {
                    $scope.shouldShowSlideIn = $scope.showSlideIn($scope.checkoutState.product.id, $scope.checkoutState.bump.id);
                    $scope.includesStickyBumpWidget = $scope.hasStickyBumpWidget(product);
                }

            });

            function getUrlVars() {
                var vars = {};
                var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                    vars[key] = decodeURIComponent(value);
                });
                return vars;
            }

            /**
             * Prepopulate select fields with values from the query string.
             *
             * Note: This uses $location which only parses the parameters after the #, for example,
             * market.samcart.com/products/pro#?email=bglass%40samcart.com&first_name=Bryan&last_name=Glass
             */
            function initializeFields(context) {
                var locationParams = $location.search();
                var vanillaParams = new getUrlVars();

                function getValueFromParams(parameterName) {
                    return locationParams[parameterName] ? locationParams[parameterName] : vanillaParams[parameterName] ? vanillaParams[parameterName] : '';
                }

                function loadAddressValuesFromContext(address) {
                    return {
                        address: address.address || address.address_line1,
                        address_line_1: address.address_line1,
                        address_line_2: address.address_line2,
                        city: address.city,
                        region: address.region,
                        postal: address.postal_code,
                        country: parseInt(address.country_name),
                    };
                }

                $scope.order.customer.first_name = getValueFromParams('first_name');
                $scope.order.customer.last_name = getValueFromParams('last_name');
                $scope.order.customer.email = getValueFromParams('email');
                $scope.order.customer.phone_number = getValueFromParams('phone_number');
                $scope.coupon_code = getValueFromParams('coupon');

                if (context) {
                    $scope.order.customer.first_name = context.cart.customer.first_name ? context.cart.customer.first_name : $scope.order.customer.first_name;
                    $scope.order.customer.last_name = context.cart.customer.last_name ? context.cart.customer.last_name : $scope.order.customer.last_name;
                    $scope.order.customer.email = context.cart.customer.email ? context.cart.customer.email : $scope.order.customer.email;
                    $scope.order.customer.email_confirmation = context.cart.customer.email ? context.cart.customer.email : $scope.order.customer.email_confirmation;
                    $scope.order.customer.phone_number = context.cart.customer.phone_number ? context.cart.customer.phone_number : $scope.order.customer.phone_number;
                    $scope.coupon_code = context.cart.coupon_code ? context.cart.coupon_code : $scope.coupon_code;
                    $scope.order.quantity = context.cart.items[0].quantity ? context.cart.items[0].quantity.toString() : $scope.order.quantity;

                    if (context.cart.billing) {
                        $scope.order.billing_address = loadAddressValuesFromContext(context.cart.billing);
                    }
                    if (context.cart.shipping) {
                        $scope.order.shipping_address = loadAddressValuesFromContext(context.cart.shipping);
                    }

                    if (getValueFromParams('context')) {
                        $http.post('/api/checkout/checkout-error').then(function (response) {
                            Checkout.showErrorModal(response.data.template);
                        });
                    }

                    var bumpItems = context.cart.items.filter(function (item) {
                        return item.type === "bump";
                    });
                    for (var i = 0; i < bumpItems.length; i++) {
                        var item = bumpItems[i];
                        if (context.pageSettings.isOrderBumpV2) {
                            $scope.toggleOrderBumps(item.product_id, item.product_id, context.cart.id, context.sandbox);
                        } else {
                            $scope.bumpSelected = true;
                            $scope.toggleOrderBump(item.product_id, context.cart.id, context.sandbox)
                        }
                    }

                    var defaultCountryId = findCountryIdByCountryCode(Context.store.country_code);
                    if (!defaultCountryId) {
                      defaultCountryId = findCountryIdByCountryCode('US');
                    }

                    if (context.pageSettings.hasBillingAddressForm && !($scope.order.billing_address && $scope.order.billing_address.country)) {
                      $scope.order.billing_address = {country: defaultCountryId}
                    }

                    if (context.pageSettings.hasShippingAddressForm && !($scope.order.shipping_address && $scope.order.shipping_address.country)) {
                      $scope.order.shipping_address = {country: defaultCountryId}
                    }
                }

                if ($scope.coupon_code && $scope.coupon_code.length > 0) {
                    $scope.checkCoupon($scope.order.original_product_id, false, $scope.sandbox);
                }

                showCustomerPricing();
            }

            /**
             * Watches the customer object in order to capture a prospect
             */
            var debounceLength = 750;
            $scope.$watch('order.customer', _.debounce(function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    $scope.captureProspect();
                }
            }, debounceLength), true);

            // Checkout 3.0?
            // This check is a way to detect if we are on a checkout page and not an upsell page.
            if (typeof window.product !== 'undefined') {
                $scope.checkoutContext = window.checkoutContext;
                syncSubscriptionsByProductId($scope.checkoutContext.order);
                if (typeof product.properties.bundled_products !== 'undefined') {
                    var bundledProducts = JSON.parse(product.properties.bundled_products);
                    // Only call a refresh if we actually have bundles.
                    setProductBundles(bundledProducts);
                    if (bundledProducts.length > 0) {
                        refreshCheckoutContext();
                    }
                }
            }

            initializeFields($scope.checkoutContext);

            function refreshCheckoutContext() {
                syncCheckoutContext();
                $scope.contextRefresh = true;
                $scope.renderingPaymentElement = $scope.createPaymentElement || $scope.refreshPaymentElement;
                $http.post('/api/v2/checkout/context', $scope.checkoutContext).then(function (response) {
                    _.extend($scope.checkoutContext.order, response.data.order);
                    _.extend($scope.checkoutContext.payment, response.data.payment);
                    syncSubscriptionsByProductId(response.data.order);

                    if (Context.store.has_tax_address_validation) {
                        displayTaxAddressAlert();
                    }
                    // update what to show under products in order summary based on giftable status
                    $scope.showGiftInformation = false;
                    var invoiceItems = $scope.checkoutContext.order.invoice.items;

                    invoiceItems.forEach(function (invoiceItem) {
                        if (invoiceItem.allow_gifting) {
                            $scope.showGiftInformation = true;
                        }
                    });

                    if (!$scope.showGiftInformation) {
                        $scope.giftedProducts = {};
                    }

                    var totalElem = document.getElementById("total");
                    if (totalElem.firstChild.tagName === 'FONT') {
                        var newTotal = $filter('currency')($scope.checkoutContext.order.invoice.total);
                        newTotal = [newTotal.slice(0, 1), ' ', newTotal.slice(1)].join('');
                        totalElem.firstChild.innerHTML = newTotal;
                    }

                    if ($scope.checkoutContext.payment.isPaymentElementEnabled) {
                        managePaymentElement();
                    }

                    $scope.shippingCountryIsRestricted = response.data.cart.settings.shipping_country_is_restricted
                      && response.data.cart.shipping.country_code
                      && response.data.cart.shipping.region;
                }).finally(function () {
                    $scope.contextRefresh = false;
                    $scope.renderingPaymentElement = false;
                    if ($scope.refreshPaymentElement) {
                        $scope.refreshPaymentElement = false;
                        $scope.paymentElement.mount('#payment-element');
                    }
                    $scope.zipCodeError = false;
                });
            }

            function managePaymentElement() {
                var subscriptions = $scope.checkoutContext.order.subscriptions;
                $scope.sampaySubscriptionCount = subscriptions ? subscriptions.length : 0;
                if ($scope.sampayElements && ($scope.sampayElementAmount !== $scope.checkoutContext.order.invoice.total || subscriptions.length > 0)) {
                    if ($scope.checkoutContext.order.invoice.total !== 0 && subscriptions.length === 0) {
                        $scope.sampayElementAmount = $scope.checkoutContext.order.invoice.total;
                        $scope.sampayElements.update({
                            mode: 'payment',
                            amount: $scope.sampayElementAmount,
                            currency: $scope.checkoutContext.order.invoice.currency.toLowerCase(),
                        });
                    } else {
                        $scope.sampayElementAmount = $scope.checkoutContext.order.invoice.total;
                        $scope.sampayElements.update({
                            mode: 'setup',
                        });
                    }
                }

                $scope.renderingPaymentElement = false;

                if ($scope.createPaymentElement) {
                    $scope.createPaymentElement = false;
                    var elements;
                    //API for styling: https://stripe.com/docs/elements/appearance-api?locale=en-GB
                    var appearance = {
                        theme: 'none',
                        variables: {
                            colorPrimary: '#232427',
                            colorDanger: '#FE435A',
                            fontFamily: 'Roboto, sans-serif',
                        },
                        rules: {
                            '.Input, .CodeInput, .CheckboxInput': {
                                border: '1px solid #9CABB7',
                                borderRadius: '8px',
                                outline: 'none',
                            },
                            '.Input:focus, .CodeInput:focus, .CheckboxInput:focus': {
                                border: '1px solid #2096EF',
                                boxShadow: 'inset 0 0 0 1px #2096EF',
                                borderRadius: '8px',
                                outline: 'none',
                            },
                            '.Input--invalid': {
                                border: '1px solid #FE435A',
                                boxShadow: 'inset 0 0 0 1px #FE435A',
                                borderRadius: '8px',
                                outline: 'none',
                            },
                            '.AccordionItem--selected': {
                                border: '1px solid #9CABB7',
                                boxShadow: 'none',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                color: '#046BD9',
                            },
                            '.TabIcon': {
                                color: '#5A7386',
                            },
                            '.TabIcon--selected': {
                                color: '#046BD9',
                            },
                            '.AccordionItem:hover': {
                                border: '1px solid #9CABB7',
                                boxShadow: 'none',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '8px',
                                color: '#233543',
                            },
                            '.AccordionItem': {
                                border: '1px solid #DEE3E7',
                                borderRadius: '8px',
                                color: '#5A7386',
                            },
                            '.PickerItem': {
                                border: '1px solid #DEE3E7',
                                borderRadius: '8px',
                            },
                            '.PickerItem--selected': {
                                border: '1px solid #9CABB7',
                            },
                            '.PickerItem:hover': {
                                border: '1px solid #9CABB7',
                            },
                            '.Dropdown': {
                                border: '1px solid #9CABB7',
                                borderRadius: '8px',
                            },
                            '.DropdownItem--highlight': {
                                backgroundColor: '#F4F7FA',
                            },
                            '.MenuAction': {
                                backgroundColor: '#ffffff',
                            },
                            '.MenuAction:hover': {
                                backgroundColor: '#f4f4f4',
                            },
                        },
                    };
                    var fonts = [{
                        family: 'Roboto',
                        cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                    }];
                    $scope.sampayElementAmount = $scope.checkoutContext.order.invoice.total;
                    if ($scope.checkoutContext.payment.usePaymentMethodConfiguration) {
                        elements = createPaymentElementWithPaymentConfiguration(appearance, fonts);
                    } else {
                        elements = createPaymentElementWithPaymentMethodType(appearance, fonts);
                    }
                    $scope.sampayElements = elements;

                    $scope.paymentElement = $scope.sampayElements.create(
                        'payment',
                        {
                            layout: {
                                type: 'accordion',
                                defaultCollapsed: false,
                                radios: false,
                                spacedAccordionItems: true,
                            },
                            wallets: {
                                applePay: 'never',
                                googlePay: 'never',
                            },
                            terms: {
                              applePay: 'never',
                              auBecsDebit: 'never',
                              bancontact: 'never',
                              card:'never',
                              cashapp: 'never',
                              googlePay: 'never',
                              ideal: 'never',
                              paypal: 'never',
                              sepaDebit: 'never',
                              sofort: 'never',
                              usBankAccount: 'never',
                            },
                            paymentMethodOrder: [
                              'card',
                              'affirm',
                              'afterpay_clearpay',
                              'klarna',
                            ],
                        }
                    );
                }
            }

            function displayTaxAddressAlert() {
                if ($scope.checkoutContext.order.invoice.tax_transaction != null && $scope.checkoutContext.order.invoice.tax_transaction.error) {
                    $scope.zipCodeError = true;
                }

                if ($scope.zipCodeError === true && !$scope.checkoutContext.order.invoice.tax_transaction.error.message.includes('from_zip') && $scope.zipCodeErrorDisplayCount < 2) {
                    $scope.zipCodeErrorMessage = ($scope.checkoutContext.order.invoice.tax_transaction.error.message.includes('to_zip')
                        ? 'Could not calculate taxes. Please check your billing and shipping zip code'
                        : 'Please check that your address is correct');
                    Checkout.showErrorModal('<h2>' + $scope.zipCodeErrorMessage + '</h2>');
                    $scope.zipCodeErrorDisplayCount++;
                }
            }

            function syncNameYourOwnPrice() {
                var itemIndex = 0;
                $scope.checkoutContext.cart.items.map(function (x, index) {
                    if (x.product_id === $scope.order.original_product_id) {
                        itemIndex = index;
                    }
                });

                $scope.order.customer_provided_price_cents = ($scope.order.customer_provided_price * 100).toFixed();

                if (!$scope.checkoutContext.cart.legacyCart) {
                    $scope.checkoutContext.cart.legacyCart = {};
                }

                $scope.checkoutContext.cart.legacyCart.customer_provided_price = $scope.order.customer_provided_price;
                $scope.checkoutContext.cart.legacyCart.customer_provided_price_cents = $scope.order.customer_provided_price * 100;

                if (itemIndex >= 0 && $scope.checkoutState.productPrice <= $scope.order.customer_provided_price_cents) {
                    $scope.checkoutContext.cart.items[itemIndex].price = $scope.order.customer_provided_price_cents;
                    $scope.checkoutContext.cart.items[itemIndex].customer_provided_price_cents = $scope.order.customer_provided_price_cents;
                }
            }

            function createPaymentElementWithPaymentMethodType(appearance, fonts) {
                if ($scope.sampayElementAmount === 0 || $scope.sampaySubscriptionCount > 0) {
                    if ($scope.checkoutContext.payment.isSamPay && product.properties.allow_apm_by_product !== false) {
                        return StripeElements.elements({
                            mode: 'setup',
                            appearance: appearance,
                            fonts: fonts,
                        });
                    } else {
                        return StripeElements.elements({
                            mode: 'setup',
                            appearance: appearance,
                            fonts: fonts,
                            paymentMethodTypes: ['card'],
                        });
                    }
                } else {
                    if ($scope.checkoutContext.payment.isSamPay && product.properties.allow_apm_by_product !== false) {
                        return StripeElements.elements({
                            mode: 'payment',
                            amount: $scope.sampayElementAmount,
                            currency: $scope.checkoutContext.order.invoice.currency.toLowerCase(),
                            appearance: appearance,
                            fonts: fonts,
                        });
                    } else {
                        return StripeElements.elements({
                            mode: 'payment',
                            amount: $scope.sampayElementAmount,
                            currency: $scope.checkoutContext.order.invoice.currency.toLowerCase(),
                            appearance: appearance,
                            fonts: fonts,
                            paymentMethodTypes: ['card'],
                        });
                    }
                }
            }

            function createPaymentElementWithPaymentConfiguration(appearance, fonts) {
                if ($scope.sampayElementAmount === 0 || $scope.sampaySubscriptionCount > 0) {
                    return StripeElements.elements({
                        mode: 'setup',
                        appearance: appearance,
                        fonts: fonts,
                        paymentMethodConfiguration: $scope.checkoutContext.payment.paymentMethodConfiguration,
                    });
                } else {
                    return StripeElements.elements({
                        mode: 'payment',
                        amount: $scope.sampayElementAmount,
                        currency: $scope.checkoutContext.order.invoice.currency.toLowerCase(),
                        appearance: appearance,
                        fonts: fonts,
                        paymentMethodConfiguration: $scope.checkoutContext.payment.paymentMethodConfiguration,
                    });
                }
            }


            function syncProductId() {
                if (!$scope.checkoutContext.cart.legacyCart) {
                    $scope.checkoutContext.cart.legacyCart = {};
                }

                if ($scope.selected_product_id) {
                    $scope.checkoutContext.cart.legacyCart.selected_product_id = $scope.selected_product_id;
                }

                if ($scope.original_product_id) {
                    $scope.checkoutContext.cart.legacyCart.original_product_id = $scope.original_product_id;
                }
            }

            function syncCheckoutContext() {
                syncCustomer();
                syncBillingAddress();
                syncShippingAddress();
                syncCoupon();
                syncNameYourOwnPrice();
                syncPrimaryProduct();
                syncOrderBump();
                syncProductBundles();
                syncRecaptcha();
                syncProductId();
                syncGifts();
            }

            $scope.updateNameYourOwnPrice = function () {
                syncNameYourOwnPrice();
                refreshCheckoutContext();
            };

            function syncCoupon() {
                if ($scope.isCouponValid) {
                    $scope.checkoutContext.cart.coupon_code = $scope.coupon_code;
                } else {
                    $scope.checkoutContext.cart.coupon_code = '';
                }
            }

            function syncPrimaryProduct() {
                $scope.checkoutContext.cart.items[0].product_id = $scope.checkoutContext.cart.settings.selected_payment_option;
                $scope.checkoutContext.cart.items[0].quantity = parseInt($scope.order.quantity, 10);
                $scope.checkoutContext.cart.items[0].type = 'primary';

                if (!$scope.checkoutContext.cart.legacyCart) {
                    $scope.checkoutContext.cart.legacyCart = {};
                }

                $scope.checkoutContext.cart.legacyCart.quantity = parseInt($scope.order.quantity, 10);
            }

            $scope.removeOrderBumpFromCart = function (product_id, invoiceItem, template, cart_id, sandbox) {
                if ($scope.useMultipleOrderBumps) {
                    $scope.toggleOrderBumps(product, invoiceItem.product_id, false, sandbox);
                } else {
                    $scope.bumpSelected = false;
                    $scope.toggleOrderBump(product, cart_id, sandbox);
                }
            }

            function syncOrderBump() {
                var items = $scope.checkoutContext.cart.items;

                if (!$scope.checkoutContext.cart.legacyCart) {
                    $scope.checkoutContext.cart.legacyCart = {};
                }

                if ($scope.checkoutState.bump) {
                    var orderBumpProductId = $scope.checkoutState.bump.id;

                    if ($scope.order_bump_checked) {
                        $scope.checkoutContext.cart.legacyCart.bump_id = orderBumpProductId;
                        var existing = _.find(items, function (item) {
                            return item.product_id == orderBumpProductId;
                        });
                        // Find returns undefined if not found
                        if (typeof existing == 'undefined') {
                            // it wasn't found so add it - else don't add because it already exists (changing payment options)
                            items.push({
                                product_id: orderBumpProductId,
                                quantity: 1,
                                type: 'bump',
                            });
                        }
                    } else {
                        $scope.checkoutContext.cart.legacyCart.bump_id = null;
                        // remove order bump from cart
                        items = _.filter(items, function (item) {
                            return item.product_id != orderBumpProductId;
                        });

                    }
                }

                if ($scope.order_bumps.size) {
                    $scope.order_bumps.forEach(function (order_bump) {
                        var existing = _.find(items, function (item) {
                            return item.product_id === order_bump;
                        });

                        if (typeof existing == 'undefined') {
                            items.push({
                                product_id: order_bump,
                                quantity: 1,
                                type: 'bump',
                            });
                        }
                    });
                }

                if ($scope.useMultipleOrderBumps) {
                    // Remove order bumps from items that are no longer selected
                    items = _.filter(items, function (item) {
                        // Keep all items in cart that aren't order bumps or are order bumps that appear in the list of added order bumps.
                        return (item.type !== 'bump' || (item.type === 'bump' && $scope.order_bumps.has(item.product_id)));
                    });
                }

                $scope.checkoutContext.cart.items = items;
            }

            function syncProductBundles() {
                var items = $scope.checkoutContext.cart.items;

                if ($scope.bundledProducts.length > 0) {
                    // Remove existing bundles to reset
                    items = _.filter(items, function (item) {
                        return item.type !== 'bundled';
                    });
                    $scope.bundledProducts.forEach(function (product) {
                        items.push({
                            product_id: product.product_id,
                            quantity: 1,
                            type: 'bundled',
                        });
                    });
                }

                if ($scope.bundledProducts.length == 0) {
                    // Removes bundled products if we select a product without them
                    items = _.filter(items, function (item) {
                        return item.type !== 'bundled';
                    });
                }

                $scope.checkoutContext.cart.items = items;
            }

            function setProductBundles(items) {
                // Reset bundles
                $scope.bundledProducts = [];

                if (items.length > 0) {
                    items.forEach(function (product) {
                        $scope.bundledProducts.push({
                            product_id: product.id,
                            quantity: 1,
                            type: 'bundled',
                        });
                    });
                }
            }

            function setBrowser() {
                // Internet Explorer 6-11
                $scope.isIE = /*@cc_on!@*/false || !!document.documentMode;

                // Edge 20+
                $scope.isEdge = !$scope.isIE && !!window.StyleMedia;

                // Safari 3.0+ "[object HTMLElementConstructor]"
                $scope.isSafari = /constructor/i.test(window.HTMLElement) ||
                    (function (p) {
                        return p.toString() === "[object SafariRemoteNotification]";
                    })(!window['safari'] ||
                        (typeof safari !== 'undefined' && window['safari'].pushNotification));

                // Firefox 1.0+
                $scope.isFirefox = typeof InstallTrigger !== 'undefined';

                if (navigator.userAgent.indexOf('Edg') >= 0) {
                    $scope.isChrome = false;
                    $scope.isEdge = true;
                } else {
                    $scope.isChrome = !!window.chrome;
                }
            }

            function fallbackPaymentType() {
                if ($scope.processor_selection === 'digital_wallet') {
                    var checkedRadio = document.getElementById('creditCardRadio') || document.getElementById('payPalRadio');
                    if (checkedRadio.id === 'creditCardRadio') {
                        $scope.setPaymentType('cc_processor');
                        var cards = document.getElementById('cards');
                        if (cards) {
                            cards.classList.add('show');
                        }
                    } else {
                        $scope.setPaymentType('paypal');
                        var paypal = document.getElementById('paypal');
                        if (paypal) {
                            paypal.classList.add('show');
                        }
                    }
                    checkedRadio.checked = true;
                }
            }

            function syncCustomer() {
                $scope.checkoutContext.cart.customer = {
                    first_name: $scope.order.customer.first_name,
                    last_name: $scope.order.customer.last_name,
                    email: $scope.order.customer.email,
                    phone_number: $scope.order.customer.phone_number,
                    customer_provided_price: $scope.order.customer_provided_price || 0,
                };
            }

          /**
           * Pre-es6 version to make gulp-uglify happy.
           * @see https://stackoverflow.com/a/47990860
           */
            function arrayColumn(array, columnName) {
              return array.map(function(value,index) {
                return value[columnName];
              })
            }

          /**
           *  The logic here might seem a little weird BUT basically we want "if there's a country and region set, and
           *  we have a specific list of regions in the country, make sure the region is in the list. Elsewise, it's OK"
           */
            function isValidRegion(country, region) {
              if (!country || !region) {
                return true;
              }

              if (typeof $scope.showRegionDropdown !== "function" || !$scope.showRegionDropdown(country)) {
                return true;
              }

              if ($scope.checkoutContext.pageSettings.isV1LegacyTemplate) {
                return true;
              }

              return arrayColumn($scope.regionsForSelectedCountry(country), 'data').includes(region);
            }

            function syncBillingAddress() {
                if (!isValidRegion($scope.order.billing_address.country, $scope.order.billing_address.region)) {
                  $scope.order.billing_address.region = undefined;
                }

                $scope.checkoutContext.cart.billing = {
                    address: $scope.order.billing_address.address || $scope.order.billing_address.address_line1,
                    address_line_1: $scope.order.billing_address.address_line1,
                    address_line_2: $scope.order.billing_address.address_line2,
                    city: $scope.order.billing_address.city,
                    region: $scope.order.billing_address.region,
                    postal_code: $scope.order.billing_address.postal,
                    country_name: $scope.order.billing_address.country,
                    country_code: findCountryCodeByCountryId($scope.order.billing_address.country),
                };
            }

            function syncShippingAddress() {
                if (!isValidRegion($scope.order.shipping_address.country, $scope.order.shipping_address.region)) {
                  $scope.order.shipping_address.region = undefined;
                }

                $scope.checkoutContext.cart.shipping = {
                    address: $scope.order.shipping_address.address || $scope.order.shipping_address.address_line1,
                    address_line_1: $scope.order.shipping_address.address_line1,
                    address_line_2: $scope.order.shipping_address.address_line2,
                    city: $scope.order.shipping_address.city,
                    region: $scope.order.shipping_address.region,
                    postal_code: $scope.order.shipping_address.postal,
                    country_name: $scope.order.shipping_address.country,
                    country_code: findCountryCodeByCountryId($scope.order.shipping_address.country),
                };
            }

            function syncRecaptcha() {
                $scope.checkoutContext.recaptchaResponseV2 = window.recaptchaResponse ? window.recaptchaResponse : "";
            }

            function findCountryCodeByCountryId(countryId) {
                var country = _.find($scope.countries, function (country) {
                    return country.id === countryId;
                });

                return country ? country.iso_3166_2 : undefined;
            }

            function findCountryIdByCountryCode(countryCode) {
              var country = _.find(Context.store.countries, function (country) {
                return country.iso_3166_2 === countryCode;
              });

              return country ? country.id : undefined;
            }

            function syncSubscriptionsByProductId(order) {
                if (order.subscriptions && Array.isArray(order.subscriptions)) {
                    $scope.subscriptionsByProductId = order.subscriptions.reduce(function(acc, subscription) {
                        acc[subscription.product_id] = subscription;
                        return acc;
                    }, {});
                }
            }

            function setBundledProducts(bundledProducts) {
                var items = $scope.checkoutContext.cart.items;

                if (bundledProducts.length > 0) {
                    // Remove existing bundles to reset
                    items = _.filter(items, function (item) {
                        return item.type != 'bundled';
                    });
                    bundledProducts.forEach(function (product) {
                        items.push({
                            product_id: product.id,
                            quantity: 1,
                            type: 'bundled',
                        });
                    });
                    $scope.bundledProducts = items;
                    refreshCheckoutContext();
                }
            }

            // Get credit card placeholders from language settings
            var cardPlaceholder = window.productLanguageSetting.cc_number || 'Card Number';
            var expPlaceholder = window.productLanguageSetting.expiration_text || 'MM / YY';
            var cvcPlaceholder = window.productLanguageSetting.cvv || 'CVC';
            var postalCodePlaceholder = window.productLanguageSetting.postal_code || 'Postal Code';
            if (Placeholders[template]) {
                cardPlaceholder = Placeholders[template].cardNumber || '';
                expPlaceholder = Placeholders[template].cardExpiry || '';
                cvcPlaceholder = Placeholders[template].cardCvc || '';
                postalCodePlaceholder = Placeholders[template].postalCode || '';
            }

            if (EasyPayDirect) {
                if (CollectJS) {
                  var collectClient = CollectJS;
                  setBrowser();
                  collectClient.configure({
                    "variant": "inline",
                    "styleSniffer": "true",
                    "fields": {
                      "ccnumber": {
                        "selector": "#ccnumber",
                        "title": "Card Number",
                        "placeholder": cardPlaceholder || 'Card Number',
                      },
                      "ccexp": {
                        "selector": "#ccexp",
                        "title": "Card Expiration",
                        "placeholder": expPlaceholder || 'MM / YY',
                      },
                      "cvv": {
                        "display": "show",
                        "selector": "#cvv",
                        "title": "CVV Code",
                        "placeholder": cvcPlaceholder || 'CVV',
                      },
                    },
                    'validationCallback': function (field, status, message) {
                      var errorElement = document.getElementById(field + '-error');

                      if (!status) {
                        errorElement.hidden = false;
                      } else if (status && errorElement.hidden != true) {
                        errorElement.hidden = true;
                      }
                    },
                    "timeoutDuration": 4000, // milliseconds or 4.0 seconds
                  });

                  if ($scope.isChrome === true) {
                    collectClient.configure({
                      "fields": {
                        "googlePay": {
                          "selector": ".googlePayButton",
                          'emailRequired': true,
                        },
                      },
                    });
                  }

                  // define template;
                  var template = 'salesletter';

                  if (typeof window.product !== 'undefined') {
                    template = product.properties.template;
                  } else if (typeof window.template !== 'undefined') {
                    template = window.template;
                  }

                  // check for template param to override template styling
                  var searchParams = window.location.search;
                  if (searchParams) {
                    // parse template param: "?template='xyz'"">
                    var urlParams = new URLSearchParams(searchParams);
                    if (urlParams.has('template')) {
                      var templateOverride = urlParams.get('template');
                      if (templateOverride && templateOverride.length) {
                        template = templateOverride;
                      }
                    }
                  }

                    // set styles to corresponding template
                    var templateStyles;
                    if (typeof EasyPayTemplateStyles[template] === 'undefined') {
                        collectClient.config.placeholderCss = {"color": "#C9D3DC"};
                        collectClient.config.focusCss = {"border-color": "#1D96F3"};
                        collectClient.config.customCss = {
                            "color": "#565E66",
                            "background-color": "#fff",
                            "border": "1px solid #C9D3DC",
                            "border-radius": "3px",
                            "padding": "2px 20px",
                        };
                    } else {
                        templateStyles = EasyPayTemplateStyles[template]
                        collectClient.config.placeholderCss = templateStyles.placeholderCss;
                        collectClient.config.focusCss = templateStyles.focusCss;
                        collectClient.config.invalidCss = templateStyles.invalidCss;
                        collectClient.config.customCss = templateStyles.customCss;
                    }

                    if (window.showDigitalWallets) {
                        $scope.canMakePaymentResult = !$scope.isFirefox && !$scope.isEdge && !$scope.isSafari ? {
                            applePay: false,
                            googlePay: true,
                        } : null;
                        collectClient.config.callback = function (response) {
                            if ($("#paymentForm").valid()) {
                                if (terms_required && $scope.order.terms_checked === false) {
                                    $scope.termsNotAccepted = true;
                                } else {
                                    $scope.termsNotAccepted = false;
                                    $scope.order.easypaydirect_token = response.token;
                                    $scope.checkoutContext.payment.easypaydirect_token = response.token;

                                    $scope.isProcessingOrder = true;
                                    submitPaymentRequest();
                                }
                            }
                        }
                    }

                } else {
                    fallbackPaymentType();
                }
            }

            if (Braintree) {
                braintree.client.create({
                    authorization: window.samcart.braintreeClientToken,
                }, function (err, clientInstance) {
                    if (err) {
                        console.error(err);
                        return;
                    }

                    var fields = {
                        number: {
                            selector: '#card-number',
                            placeholder: cardPlaceholder || 'Card Number',
                        },
                        cvv: {
                            selector: '#card-cvv',
                            placeholder: cvcPlaceholder || 'CVV',
                        },
                        expirationDate: {
                            selector: '#card-expiry',
                            placeholder: expPlaceholder || 'MM / YY',
                        },
                    };

                    if ($scope.isVerifyZipCode) {
                        fields = Object.assign({
                            postalCode: {
                                selector: '#postal-code',
                                placeholder: postalCodePlaceholder || 'Postal Code',
                            },
                        }, fields);
                    }

                    braintree.hostedFields.create({
                        client: clientInstance,
                        styles: {
                            'input': {
                                'font-size': '14px',
                                'line-height': '2',
                                'color': '#565e66',
                            },
                        },
                        fields: fields,
                    }, function (err, hostedFieldsInstance) {
                        if (err) {
                            console.error(err);
                            return;
                        }
                        hostedFieldsInstance.on('validityChange', function (event) {
                            var field = event.fields[event.emittedBy];

                            // Remove any previously applied error or warning classes
                            $(field.container).removeClass('is-valid');
                            $(field.container).removeClass('is-invalid');

                            if (field.isValid) {
                                $(field.container).addClass('is-valid');
                            } else if (field.isPotentiallyValid) {
                                // skip adding classes if the field is
                                // not valid, but is potentially valid
                            } else {
                                $(field.container).addClass('is-invalid');

                            }
                        });
                        $scope.braintreeHostedFieldsInstance = hostedFieldsInstance;
                    });
                });
            }

            if (StripeElements && StripeElements.elements) {
                var template = 'salesletter';
                if (typeof window.product !== 'undefined') {
                    template = product.properties.template;
                } else if (typeof window.template !== 'undefined') {
                    template = window.template;
                }
                var elementStyles;

                if (typeof TemplateStyles[template] === 'undefined') {
                    elementStyles = {
                        base: {
                            color: '#555',
                            fontWeight: 300,
                            fontFamily: 'Arial, Helvetica, sans-serif',
                            fontSize: '16px',

                            '::placeholder': {
                                color: '#555',
                            },
                        },
                        invalid: {
                            color: '#E25950',
                        },
                    };
                } else {
                    elementStyles = TemplateStyles[template];
                }

                var elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                };

                var elements = StripeElements.elements({
                    fonts: [{
                        family: 'TextaAlt',
                        src: "url('https://samcart.com/modules/shared/fonts/TextaAlt.ttf')",
                    }],
                });

                $scope.cardNumber = elements.create('cardNumber', {
                    classes: elementClasses,
                    style: elementStyles,
                    placeholder: cardPlaceholder,
                });
                $scope.cardNumber.addEventListener('change', function (event) {
                    if (event && event.error && event.elementType === 'cardNumber') {
                        $scope.cardNumberError = event.error.message;
                    } else {
                        $scope.cardNumberError = "";
                    }
                });

                $scope.cardExpiry = elements.create('cardExpiry', {
                    classes: elementClasses,
                    style: elementStyles,
                    placeholder: expPlaceholder,
                });
                $scope.cardExpiry.addEventListener('change', function (event) {
                    if (event && event.error && event.elementType === 'cardExpiry') {
                        $scope.cardExpiryError = event.error.message;
                    } else {
                        $scope.cardExpiryError = "";
                    }
                });

                $scope.cardCvc = elements.create('cardCvc', {
                    classes: elementClasses,
                    style: elementStyles,
                    placeholder: cvcPlaceholder,
                });
                $scope.cardCvc.addEventListener('change', function (event) {
                    if (event && event.error && event.elementType === 'cardCvc') {
                        $scope.cardCvcError = event.error.message;
                    } else {
                        $scope.cardCvcError = "";
                    }
                });

                if ($scope.isVerifyZipCode) {
                    $scope.postalCode = elements.create('postalCode', {
                        classes: elementClasses,
                        style: elementStyles,
                        placeholder: postalCodePlaceholder,
                    });
                    $scope.postalCode.addEventListener('change', function (event) {
                        if (event && event.error && event.elementType === 'postalCode') {
                            $scope.postalCodeError = event.error.message;
                        } else {
                            $scope.postalCodeError = '';
                        }
                    });
                }

                if (window.showDigitalWallets) {
                    var payload = {
                        country: window.context.country_code,
                        currency: window.context.currency_code.toLowerCase(),
                        total: {
                            label: 'TOTAL',
                            amount: $scope.checkoutContext.order.invoice.total,
                        },
                        displayItems: [],
                    };
                    $scope.checkoutContext.order.invoice.items.forEach(function (invoiceItem) {
                        payload.displayItems.push({
                            label: invoiceItem.description,
                            amount: invoiceItem.amount,
                        })
                    });
                    if ($scope.checkoutContext.order.invoice.shipping > 0) {
                        payload.shippingOptions = [];
                        payload.shippingOptions.push({
                            amount: $scope.checkoutContext.order.invoice.shipping,
                        });
                    }
                    $scope.paymentRequest = StripeElements.paymentRequest(payload);
                    $scope.prButton = elements.create('paymentRequestButton', {
                        paymentRequest: $scope.paymentRequest,
                    });
                    $scope.paymentRequest.canMakePayment().then(function (result) {
                        $scope.canMakePaymentResult = result;
                        var paymentRequestDivs = document.querySelectorAll('#payment-request-button');
                        if (result && (result.applePay || result.googlePay)) {
                            var stripeButtons = {
                                desktop: null,
                                mobile: null,
                            };
                            for (var i = 0; i < paymentRequestDivs.length; i++) {
                                var classNames = paymentRequestDivs[i].className.split(' ');
                                if (classNames.indexOf('mobile') > -1) {
                                    stripeButtons.mobile = paymentRequestDivs[i];
                                } else {
                                    stripeButtons.desktop = paymentRequestDivs[i];
                                }
                            }
                            if (window.innerWidth < 768 && stripeButtons.mobile) {
                                $scope.prButton.mount(stripeButtons.mobile);
                            } else {
                                if (stripeButtons.desktop) {
                                    $scope.prButton.mount(stripeButtons.desktop);
                                } else {
                                    $scope.canMakePaymentResult = null;
                                    fallbackPaymentType();
                                }
                            }
                        } else {
                            for (var j = 0; j < paymentRequestDivs.length; j++) {
                                paymentRequestDivs[j].style.display = 'none';
                            }
                            fallbackPaymentType();
                        }
                    });
                    /**
                     * Watches the checkoutContext order to keep digital wallets in sync
                     * Sets the browser variable to determine which icon to show
                     * */
                    $scope.$watch('checkoutContext.order', _.debounce(function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            if ($scope.canMakePaymentResult) {
                                syncPaymentRequest();
                            }
                        }
                    }, debounceLength), true);
                    setBrowser();
                    $scope.paymentRequest.on('paymentmethod', function (event) {
                        if ($("#paymentForm").valid()) {
                            if (terms_required && $scope.order.terms_checked === false) {
                                $scope.termsNotAccepted = true;
                                event.complete('fail');
                            } else {
                                $scope.termsNotAccepted = false;
                                event.complete('success');
                                $scope.order.stripe_token = event.paymentMethod.id;
                                $scope.checkoutContext.payment.stripe_token = event.paymentMethod.id;
                                submitPaymentRequest();
                            }
                        } else {
                            event.complete('fail');
                        }
                    });
                }
            } else {
                fallbackPaymentType();
            }

            if (($scope.checkoutContext && $scope.checkoutContext.pageSettings && $scope.checkoutContext.pageSettings.isTaxEnabled)
                || (typeof product !== 'undefined' && product.physical)
            ) {
                $scope.$watchGroup([
                    'order.billing_address.region',
                    'order.billing_address.country',
                    'order.billing_address.postal',
                    'order.shipping_address.region',
                    'order.shipping_address.country',
                    'order.shipping_address.postal',
                ], _.debounce(function () {
                    refreshCheckoutContext();
                }, 750));
            }

            function submitPaymentRequest() {
                // Check for coupon
                if ($scope.cart_coupon.code !== '') {
                    $scope.order.coupon_code = $scope.cart_coupon.code;
                } else {
                    $scope.order.coupon_code = '';
                }
                if ($scope.checkoutState.bump != null && $scope.checkoutState.bump.id != 0 && $scope.order_bump_checked) {
                    $scope.order.bump_id = $scope.checkoutState.bump.id;
                } else {
                    $scope.order.bump_id = undefined;
                }
                // Ensure checkout context is in sync (this can be removed once $scope.order is no longer used)
                prepSubmit().then(function () {
                    syncCheckoutContext();
                    $scope.order.visitation_token = window.visitorToken;
                    Checkout.funnelStart();
                    Checkout.captureOrder($scope.order, $scope.checkoutContext);
                });
            }

            function syncPaymentRequest() {
                var payload = {
                    total: {
                        label: 'TOTAL',
                        amount: $scope.checkoutContext.order.invoice.total,
                    },
                    displayItems: [],
                };
                $scope.checkoutContext.order.invoice.items.forEach(function (invoiceItem) {
                    payload.displayItems.push({
                        label: invoiceItem.description,
                        amount: invoiceItem.amount,
                    })
                });
                if ($scope.checkoutContext.order.invoice.shipping > 0) {
                    payload.shippingOptions = [];
                    payload.shippingOptions.push({
                        label: 'SHIPPING',
                        amount: $scope.checkoutContext.order.invoice.shipping,
                    });
                }
                $scope.paymentRequest.update(payload);
            }

            $scope.isUnitedStates = function (country) {
                return country === 840;
            };

            $scope.isCanada = function (country) {
                return country === 124;
            };

            $scope.showRegionDropdown = function (country) {
                return country === 840
                    || country === 124
                    || country === 484
                    || country === 826
                    || country === 36;
            }

            $scope.isRecaptchaError = function () {
                var isError = $scope.isVerifyRecaptcha && window.isRecaptchaError;
                if (isError && $scope.isProcessingOrder) {
                    $scope.isProcessingOrder = false;
                }
                return isError;
            };

            $scope.validateSection = function (container, show, hide, event) {
                var valid = jQuery('#paymentForm').validate().subset(container);
                event.preventDefault();
                if (valid) {
                    $scope.showSection(show, hide);
                    if (EasyPayDirect && show == "step3active") {
                        // grab iframe elements
                        var ccNumberIframe = document.getElementById('CollectJSInlineccnumber');
                        var ccExpIframe = document.getElementById('CollectJSInlineccexp');
                        var cvvIframe = document.getElementById('CollectJSInlinecvv');

                        // reinitialize iframe
                        ccNumberIframe.src += '';
                        ccNumberIframe.height = "50px";
                        ccExpIframe.src += '';
                        cvvIframe.src += '';
                    }
                }
            };

            $scope.multiCheckCoupon = function (productId, cartId, sandbox, event) {
                event.preventDefault();
                $scope.checkCoupon(productId, cartId, sandbox);
            };

            $scope.showSection = function (show, hide) {
                $scope[hide] = false;
                $scope[show] = true;
            };

            $scope.setUpsellProductOption = function (text, id) {
                $scope.upsell.product_id = id;
            };

            function showCustomerPricing() {
                if (typeof product !== 'undefined' && product.id) {
                    if ($scope.order.customer_provided_price) {
                        $scope.order.customer_provided_price = '';
                    }

                    var filterOption = $scope.selected_product_id || product.id;
                    var option = $scope.checkoutContext.cart.paymentOptions.filter(function (e) {
                        return (e.product_id === filterOption);
                    })[0];

                    if (option && option.isCustomerPricing) {
                        $scope.customer_pricing.visible = true;
                        if (document.getElementById("customer_provided_price-error")) {
                            document.getElementById("customer_provided_price-error").style.display = "none";
                        }

                        $scope.customer_pricing.currency = Context.store.currency;
                        $scope.customer_pricing.minimum = option.customerPricingMinimum;
                        if ($scope.selected_product == "product") {
                            $scope.customer_pricing.suggested_price = product.properties.suggested_price;
                            $scope.customer_pricing.name_your_price_text = product.properties.name_your_price_text || "Name Your Price";
                            $scope.customer_pricing.minimum_text = "Can be zero or " + product.customer_pricing_minimum + "-99999.99";
                        } else {
                            $scope.customer_pricing.suggested_price = $scope.selected_product.properties.suggested_price;
                            $scope.customer_pricing.name_your_price_text = $scope.selected_product.properties.name_your_price_text || "Name Your Price";
                            $scope.customer_pricing.minimum_text = "Can be zero or " + $scope.selected_product.customer_pricing_minimum + "-99999.99";
                        }

                        $scope.customer_pricing.title = "Please enter an amount greater than or equal to " + $scope.customer_pricing.currency + $scope.customer_pricing.minimum;
                    } else {
                        $scope.customer_pricing.visible = false;
                        $scope.customer_pricing.suggested_price = '';
                        $scope.customer_pricing.minimum = '';
                        $scope.customer_pricing.name_your_price_text = '';
                        $scope.customer_pricing.minimum_text = '';
                        $scope.customer_pricing.title = '';
                    }

                    $scope.updateNameYourOwnPrice();
                }
            }

            function syncGifts() {
                var items = $scope.checkoutContext.cart.items;

                items.forEach(function (item) {
                    if (item.product_id in $scope.giftedProducts) {
                        item.is_gift = $scope.giftedProducts[item.product_id];
                    } else {
                        item.is_gift = false;
                    }
                })
            }

            $scope.$watch('includes_gift', function () {
                if ($scope.checkoutContext) {
                    var invoiceItems = $scope.checkoutContext.order.invoice.items;

                    if (invoiceItems[0].allow_gifting && $scope.includes_gift) {
                        initProductIsGift(invoiceItems[0].product_id);
                    }

                    if (!$scope.includes_gift) {
                        $scope.giftedProducts = {};
                    }
                }
            });

            $scope.$watch('giftedProducts', function (newVal, oldVal) {
                if (!angular.equals(newVal, oldVal)) {
                    var giftedProductsExist = false;
                    for (var giftedProduct in $scope.giftedProducts) {
                        if ($scope.giftedProducts[giftedProduct]) {
                            giftedProductsExist = true;
                        }
                    }
                    if (!giftedProductsExist) {
                        $scope.includes_gift = false;
                    }
                }
            }, true);

            function initProductIsGift(productId) {
                $scope.giftedProducts[productId] = true;
            }

            function removeProductIsGift(productId) {
                delete $scope.giftedProducts[productId];
            }

            function expireCheckout() {
                if (!$scope.expire) {
                    return;
                }
                const queryParams = new URLSearchParams(window.location.search);
                const cart_token = queryParams.get('cart');
                const url = new URL('https://' + window.location.host + '/api/checkout/expire-checkout/' + cart_token);

                return fetch(url)
                    .then(function (response) {
                        return response.ok ? response.json() : false;
                    })
                    .then(function (data) {
                        data && window.location.replace(data.data.url_redirect);
                    });
            }

            /**
             * Adds an event listener to the window if we are on an upsell
             * to expire the checkout if the page is unloaded
             */
            if (window.location.pathname.split('/')[1] === 'upsell') {
                $scope.expire = true;
                $scope.isMobile = screen.width < 400 ? true : false;

                if ($scope.isMobile) {
                    document.addEventListener('visibilitychange', function () {
                        if (document.visibilityState !== 'visible') {
                            expireCheckout();
                        }
                    });
                } else {
                    window.addEventListener('beforeunload', expireCheckout);
                }
            }

            // called from the checkout_controller after form validation before purchase
            function fireAddPaymentInfo() {
                var hasScTracker = typeof window._samcarttracking !== "undefined" && typeof window._samcarttracking.tracker !== "undefined";
                var payload = {
                  "email": $scope.checkoutContext.cart.customer.email,
                  currency: $scope.checkoutContext.cart.settings.currency_iso,
                  products: $scope.checkoutContext.cart.items.map(function (item) {
                    return {
                      id: item.product_id,
                      name: $scope.checkoutState.product.name,
                      currency: $scope.checkoutContext.cart.settings.currency_iso,
                      price: item.price,
                      quantity: item.quantity,
                      coupon: item.coupon_code,
                    };
                  }),
                };

                $http.post("/api/checkout/conversion/send_add_payment_event", payload)
                    .then(function (response) {
                        if (hasScTracker) {
                            payload.eventId = response.data.eventId;
                            payload.customer = { "email": $scope.checkoutContext.cart.customer.email };
                            window._samcarttracking.tracker.fire('addPaymentInfo', payload);
                        }
                    });
            }

            function getCartTokenFromUrl() {
                if (window.location.search.includes('cart=')) {
                    var parts = window.location.search.split('cart=');
                    if (parts.length === 2 && parts[0] === '?' && parts[1].length > 0) {
                        return parts[1];
                    }
                }
                return false;
            }

            // called on load of summary, upsell
            function firePurchase() {
                var onUpsellPage = window.location.href.includes('/upsell/');
                var hasScTracker = typeof window._samcarttracking !== 'undefined';
                var cartToken = getCartTokenFromUrl();
                if (onUpsellPage && hasScTracker && cartToken) {
                    $http.get('/api/checkout/conversion/event/' + cartToken).then(function(response) {
                        var events = response.data.events;
                        events.map(function (data) {
                            if (data.event === 'purchase') {
                                window._samcarttracking.tracker.fire('purchase', {
                                    eventId: data.id,
                                    orderId: data.order_id,
                                    currency: data.currency,
                                    shipping: data.shipping,
                                    tax: data.tax,
                                    value: data.value,
                                    products: data.items.map(function(item) {
                                        return {
                                            id: item.product_id,
                                            name: item.name,
                                            currency: item.currency,
                                            price: item.price,
                                            quantity: item.quantity || 1,
                                            sku: item.sku || item.product_id,
                                            discount: item.discount || 0,
                                            coupon: item.coupon || '',
                                        };
                                    }),
                                });
                            }
                        })
                    }).catch(function() {
                        // this should be transparent if it fails since it's
                        // just analytics tracking
                    });
                }
            }
        }]);

/**
 * Format a subscription frequency unit label.
 *
 * Ex:  [[subscription.frequency | recurring:subscription.frequency_unit]]
 *
 */
angular.module('samcart').filter('recurring', ['Context', function(Context) {
    return function(frequency, unit) {
        frequency = parseInt(frequency);
        if(frequency === 1) {
            switch(unit) {
                case 'month':
                    return 'monthly';
                case 'week':
                    return 'weekly';
                case 'year':
                    return 'yearly';
                default:
                    return 'daily';
            }
        }
        return 'every ' + frequency + ' ' + unit + 's';
    };
}]);
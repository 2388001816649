angular.module("samcart").directive('autofill', ['$interval', function($interval) {
    return {
        restrict: 'A',
        require: 'ngModel',

        link: function(scope, el, attrs, ngModel) {
            var originalValue = el.val();
            var refresh = $interval(function () {
                var newValue = el.val();
                if (originalValue !== newValue) {
                    var hasTypeHint = 'autofillTypeHint' in attrs;
                    if (hasTypeHint && newValue) {
                        var _parts = newValue.split(':', 2);
                        if (_parts.length === 2) {
                            var _type = _parts[0].toLowerCase();
                            var _value = _parts[1];

                            if (_type === 'number') {
                                newValue = parseInt(_value, 10);
                            } else if (_type === 'string') {
                                newValue = _value;
                            }
                        }
                    }

                    ngModel.$setViewValue(newValue);
                }
            },
            100);
        }
    };
}]);

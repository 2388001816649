function EasyPayDirectProvider() {
  this.$get = function () {
    if (typeof (CollectJS) !== 'undefined') {
      return CollectJS
    }
  };
}

function EasyPayTemplateStylesProvider() {
  this.$get = function() {
    return {
      accelerator: {
        placeholderCss: {
          "color": "#C9D3DC",
        },
        focusCss: {
          "border-color": "#00A7EF",
          "border-top-style": "none",
          "border-right-style": "none",
          "border-left-style": "none",
        },
        customCss: {
          "color": "#232427",
          "background-color": "#fff",
          "border-bottom": ".5px solid #C9D3DC!important",
          "border-bottom-color": "#C9D3DC!important",
          "border-top-style": "none",
          "border-right-style": "none",
          "border-left-style": "none",
          "padding": "2px 2px",
          "outline-style": "none",
          "outline-color": "#C9D3DC!important",
          "text-decoration-color": "#C9D3DC!important",
          "border-bottom-width": "1px",
          "font-size": "1.125rem",
        },
      },
      amyporterfield: {
        placeholderCss: {
          "color": "#7e7e7e",
        },
        customCss: {
          "color": "#7e7e7e",
          "background-color": "#fff",
          "border-bottom": "1px dashed #a2a2a2",
          "padding": "2px 20px",
        },
      },
      blocky: {
        placeholderCss: {
          "color": "#8c9eaf",
          "font-size": "13px",
          "font-family": 'Raleway, sans-serif',
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "#ff4200",
        },
        customCss: {
          "color": "#414b55",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "4px",
          "padding": "2px 20px",
          "height": "47px",
        },
      },
      bluepro: {
        placeholderCss: {
          "color": "#777777",
          "font-size": "16px",
          "font-weight": "400",
        },
        focusCss: {
          "border-color": "#00BAFF",
        },
        customCss: {
          "color": "#000",
          "background-color": "#fff",
          "border": "1px solid #d7d7d7",
          "border-radius": "2px",
          "height":"75px",
          "padding": "0 20px",
          "font-family": "'Open Sans', sans-serif",
          "font-size": "16px",
          "font-weight": "600",
        },
      },
      businessclass: {
        customCss: {
          "color": "#333",
          "background-color": "transparent",
          "border-bottom": "1px dashed #a2a2a2",
          "padding": "2px 20px",
          "height": "48px",
          "font-family": "'open_sansregular', sans-serif",
        },
      },
      classic: {
        placeholderCss: {
          "color": "#999",
          "font-family": "'Lato', Arial, Helvetica, sans-serif",
        },
        focusCss: {
          "border-color": "#00ccff",
        },
        customCss: {
          "color": "#333",
          "background-color": "#f7f7f7",
          "border": "1px solid #d7d7d7",
          "border-radius": "2px",
          "height":"51px",
          "padding": "0 20px",
        },
      },
      cleanblue: {
        placeholderCss: {
          "color": "#999",
          "font-family": "'Lato', Arial, Helvetica, sans-serif",
        },
        focusCss: {
          "border-color": "#66afe9",
          "box-shadow" : "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)"
        },
        customCss: {
          "color": "#555",
          "background-color": "#f7f7f7",
          "border": "1px solid #d7d7d7",
          "border-radius": "2px",
          "height":"34px",
          "padding": "0 20px",
          "margin-top": "5px",
        },
      },
      coursesthatconvert: {
        placeholderCss: {
          "color": "#7e7e7e",
        },
        customCss: {
          "color": "#7e7e7e",
          "background-color": "#fff",
          "border-bottom": "1px dashed #a2a2a2",
          "padding": "2px 20px",
        },
      },
      formula: {
        focusCss: {
          "border-color": "#7fc3ff",
        }
      },
      "full-width": {
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "#ff4200",
        },
        customCss: {
          "color": "#232427",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "6px",
          "padding": "2px 20px",
          "height": "51px",
        },
      },
      launchpad: {
        placeholderCss: {
          "color": "#80868B",
          "font-family": "Roboto, sans-serif",
          "font-weight": "400",
        },
        focusCss: {
          "border-color": "#2096EF",
        },
        invalidCss: {
          "border-color": "#ff4200",
        },
        customCss: {
          "color": "#232427",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "6px",
          "padding": "2px 20px",
          "height": "45px;"
        },
      },
      machine: {
        placeholderCss: {
          "color": "#bbbbbb",
          "font-family": "'Lato', Arial, Helvetica, sans-serif",
          "font-size": "14px",
        },
        focusCss: {
          "border-color": "#66afe9",
        },
        invalidCss: {
          "border-color": "#CC0000",
        },
        customCss: {
          "color": "#555",
          "background-color": "#fff",
          "border": "1px solid #bbbbbb",
          "border-radius": "3px",
          "height":"34px",
          "padding": "0 20px",
        },
      },
      marketer: {
        placeholderCss: {
          "color": "#555",
          "font-family": "Open Sans Condensed, Arial, Helvetica, sans-serif",
          "font-size": "16px",
        },
        focusCss: {
          "border-color": "#66afe9",
        },
        invalidCss: {
          "border-color": "#CC0000",
        },
        customCss: {
          "color": "#555",
          "background-color": "#fff",
          "border": "1px solid #d7d7d7",
          "border-radius": "2px",
          "height":"35px",
          "padding": "0 20px",
          "font-weight": "600",
          "font-family": "Open Sans Condensed, Arial, Helvetica, sans-serif",
          "font-size": "16px",
        },
      },
      multistep: {
        placeholderCss: {
          "color": "#6c757d",
          "font-family": "Roboto, sans-serif",
          "font-size": "18px"
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "#FE435A",
        },
        customCss: {
          "color": "#232427",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
          "height": "48px"
        },
      },
      onepagefunnel: {
        placeholderCss: {
          "color": "#9CACB8",
          "font-weight": "300"
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "red",
        },
        customCss: {
          "color": "#565E66",
          "background-color": "#F4F7F9",
          "border": "1px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
          "height": "3rem",
          "font-weight": "400",
          "font-size": ".9rem"
        },
      },
      popupv2: {
        placeholderCss: {
          "color": "#C9D3DC",
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        customCss: {
          "color": "#565E66",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
          "font-size": "16px",
          "font-family": "Arial",
          "font-weight": "300",
        },
      },
      salesletter: {
        placeholderCss: {
          "color": "#C9D3DC",
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        customCss: {
          "color": "#565E66",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
        },
      },
      showcase:{
        placeholderCss: {
          "color": "#555",
          "font-family": "'Lato', Arial, Helvetica, sans-serif",
        },
        focusCss: {
          "border-color": "#00ccff",
        },
        invalidCss: {
          "border-color": "#CC0000",
        },
        customCss: {
          "color": "#555",
          "background-color": "#f7f7f7",
          "border": "1px solid #d7d7d7",
          "border-radius": "2px",
          "height":"51px",
          "padding": "0 20px",
          "font-weight": "400",
          "font-family": "Arial",
          "font-size": "16px"
        },
      },
      startup: {
        placeholderCss: {
          "color": "#6c757d",
          "font-family": "Roboto, sans-serif",
          "font-size": "18px"
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "#FE435A",
        },
        customCss: {
          "color": "#1D1E1E",
          "background-color": "#fff",
          "border": "2px solid #C9D3DC",
          "border-radius": "5px",
          "padding": "2px 20px",
          "height": "48px",
          "font-size": "1.125rem"
        },
      },
      "two-column": {
        placeholderCss: {
          "color": "#80868B",
          "font-family": "Roboto, sans-serif",
          "font-weight": "400",
        },
        focusCss: {
          "border-color": "#0E1330",
        },
        invalidCss: {
          "border-color": "#FE435A"
        },
        customCss: {
          "color": "#232427",
          "background-color": "#fff",
          "border": "1px solid #C0C1CA",
          "border-radius": "2px",
          "padding": "2px 20px",
          "height": "51px;"
        },
      },
      webinarsthatconvert: {
        placeholderCss: {
          "color": "#7e7e7e",
        },
        customCss: {
          "color": "#7e7e7e",
          "background-color": "#fff",
          "border-bottom": "1px dashed #a2a2a2",
          "padding": "2px 20px",
        },
      },
      worldwide: {
        placeholderCss: {
          "color": "#C9D3DC",
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        customCss: {
          "color": "#565E66",
          "background-color": "#fff",
          "border": "1px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
        },
      },
      "1pf-blueprint": {
        placeholderCss: {
          "color": "#6c757d",
          "font-family": "Roboto, sans-serif",
          "font-size": "18px"
        },
        focusCss: {
          "border-color": "#1D96F3",
        },
        invalidCss: {
          "border-color": "#FE435A",
        },
        customCss: {
          "color": "#555",
          "background-color": "#fff",
          "border": "2px solid #C9D3DC",
          "border-radius": "3px",
          "padding": "2px 20px",
          "height": "48px",
          "font-size": "1.125rem"
        },
      },
    }
  }
}

angular
  .module('samcart')
  .provider('EasyPayDirect', EasyPayDirectProvider)
  .provider('EasyPayTemplateStyles', EasyPayTemplateStylesProvider)
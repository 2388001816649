angular.module('samcart')
    .directive('errorModal', ['$rootScope', '$compile', 'Checkout', function($rootScope, $compile, Checkout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {

                var center_modal = function(element) {
                    var width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
                    var height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
                    var x_pos = (width - element.width()) / 2;
                    var y_pos = (height - element.height()) / 2;
                    if (y_pos < 0) y_pos = 0;
                    element.css({"left" : x_pos + 'px', "top" : y_pos + 'px'});
                }

                var dismissal_callback = null;

                Checkout.listen( function(template, data, fn) {
                    dismissal_callback = fn;
                    el.html(template);
                    var newScope = scope.$new();
                    newScope.data = data;
                    $compile(el.contents())(newScope);
                    el.removeClass('animated fadeOut');
                    el.addClass('animated fadeIn');
                    center_modal(el);
                    visible = true;
                    el.show();
                }, "show_error_modal");

                Checkout.listen( function() {
                    el.hide();
                    visible = false;
                    if (dismissal_callback !== null && typeof dismissal_callback !== 'undefined') {
                        dismissal_callback();
                    }
                }, "hide_error_modal");

            }
        };
    }]);

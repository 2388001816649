angular.module('samcart')
    .directive('processing', ['$rootScope', 'Checkout', function($rootScope, Checkout) {
        return {
            restrict: 'E',
            scope: { checkoutState:'=' },
            template : "<div class='processing-modal'>" +
                            "<div class='processing-wrapper'>" +
                                "<h2>Processing</h2>" + 
                                "<div class='sk-spinner sk-spinner-wave'>" +
                                    "<div class='sk-rect1'></div>" +
                                    "<div class='sk-rect2'></div>" +
                                    "<div class='sk-rect3'></div>" +
                                    "<div class='sk-rect4'></div>" +
                                    "<div class='sk-rect5'></div>" +
                                 "</div>" +
                            "</div>" +
                        "</div>",
            replace : true,
            link: function(scope, el, attrs) {

                el.hide();

                Checkout.listen(function(){
                    el.addClass('animated fadeIn');
                    el.show();
                }, "show_first_processing");

                Checkout.listen(function() {
                    el.show();
                }, "processing");

                Checkout.listen(function() {
                    el.removeClass('animated fadeIn');
                    el.hide();
                }, "processing_complete");

            }
        };
    }]);
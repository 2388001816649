angular.module("samcart").directive('links', ['$compile', function($compile) {

  var parse_url= function parse_url(str, component) {
        var query, key = ['source', 'scheme', 'authority', 'userInfo', 'user', 'pass', 'host', 'port',
        'relative', 'path', 'directory', 'file', 'query', 'fragment'
        ],
        ini = (this.php_js && this.php_js.ini) || {},
        mode = (ini['phpjs.parse_url.mode'] &&
        ini['phpjs.parse_url.mode'].local_value) || 'php',
        parser = {
        php: /^(?:([^:\/?#]+):)?(?:\/\/()(?:(?:()(?:([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?()(?:(()(?:(?:[^?#\/]*\/)*)()(?:[^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
        strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
        loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/\/?)?((?:(([^:@]*):?([^:@]*))?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/ // Added one optional slash to post-scheme to catch file:/// (should restrict this)
        };

        var m = parser[mode].exec(str),
        uri = {},
        i = 14;
        while (i--) {
        if (m[i]) {
        uri[key[i]] = m[i];
        }
        }

        if (component) {
        return uri[component.replace('PHP_URL_', '')
        .toLowerCase()];
        }
        if (mode !== 'php') {
        var name = (ini['phpjs.parse_url.queryKey'] &&
        ini['phpjs.parse_url.queryKey'].local_value) || 'queryKey';
        parser = /(?:^|&)([^&=]*)=?([^&]*)/g;
        uri[name] = {};
        query = uri[key[12]] || '';
        query.replace(parser, function($0, $1, $2) {
        if ($1) {
        uri[name][$1] = $2;
        }
        });
        }
        delete uri.source;
        return uri;
        }


        return {
          restrict : 'E',
          scope: {
            link: '@link'
          },
          template:"<a target='_blank' href=''>terms and conditions</a>",


          link: function(scope, el, attrs) {
            var parsedstring = parse_url(attrs.link);
            if (parsedstring['fragment']!=undefined) {
              var fragment = '#' + parsedstring['fragment'];
            }
            else {
              var fragment = "";
            }

            switch (parsedstring['scheme']) {
              case undefined :
                parsedstring = "http://" + parsedstring['path'] + fragment;
                break;
              case "http":
                parsedstring = "http://" + parsedstring['host'] + parsedstring['path'] + fragment;
                break;
              case "https":
                parsedstring = "https://" + parsedstring['host'] + parsedstring['path'] + fragment;
                break;
            };

            var actual_link = "<a target='_blank' href=" + "'" + parsedstring + "'" + ">Terms and Conditions</a>";
            el.html(actual_link);
            $compile(el.contents())(scope);
          }
        };
}]);
